import * as React from "react"

function UserEdit(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.7 22.2999L12.365 23.2529C12.2793 23.2774 12.1886 23.2785 12.1023 23.2561C12.016 23.2338 11.9373 23.1887 11.8742 23.1257C11.8112 23.0627 11.7662 22.9839 11.7438 22.8976C11.7214 22.8114 11.7225 22.7207 11.747 22.6349L12.7 19.2999L19.793 12.2069C20.1663 11.8156 20.6708 11.5755 21.21 11.5325C21.7492 11.4895 22.2853 11.6467 22.716 11.9739C22.9465 12.1612 23.1352 12.3948 23.2697 12.6596C23.4043 12.9245 23.4818 13.2146 23.4971 13.5112C23.5125 13.8078 23.4654 14.1044 23.3589 14.3817C23.2525 14.659 23.0889 14.9108 22.879 15.1209L15.7 22.2999Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.979 13.021L21.979 16.021"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7 19.3L15.7 22.3"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 17.5H0.5C0.499686 15.9588 1.00802 14.4605 1.94613 13.2377C2.88424 12.0149 4.19968 11.1359 5.68837 10.737C7.17707 10.3382 8.75578 10.4417 10.1796 11.0317C11.6034 11.6217 12.7927 12.6651 13.563 14"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.83801 2.59204C4.49593 3.27486 5.28486 3.81792 6.15756 4.18872C7.03026 4.55952 7.9688 4.75043 8.91701 4.75004C9.87781 4.75085 10.8286 4.55517 11.711 4.17504"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.25 4.75C3.25 5.87717 3.69777 6.95817 4.4948 7.7552C5.29183 8.55223 6.37283 9 7.5 9C8.62717 9 9.70817 8.55223 10.5052 7.7552C11.3022 6.95817 11.75 5.87717 11.75 4.75C11.75 3.62283 11.3022 2.54183 10.5052 1.7448C9.70817 0.947767 8.62717 0.5 7.5 0.5C6.37283 0.5 5.29183 0.947767 4.4948 1.7448C3.69777 2.54183 3.25 3.62283 3.25 4.75Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UserEdit
