import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { IEvaluationTemplate } from "pages/text-components/evaluations"
import { fetchJson } from "utils/network"

type TData = IEvaluationTemplate[]
type THook = (
  templateIds: (number | undefined)[],
  options?: UseQueryOptions<TData>
) => UseQueryResult<TData>

function getTemplates(templateIds: number[], enabled: boolean) {
  return enabled
    ? Promise.all(
        templateIds.map((id) =>
          // @ts-ignore
          fetchJson<IEvaluationTemplate>(`CatSatzTemplate/${id}`)
        )
      )
    : Promise.reject(new Error("Missing ids"))
}

export const useEvaluationTemplatesById: THook = (templateIds, options) => {
  const totalIdCount = templateIds.length
  const existingIds = templateIds.filter(Boolean) as number[]
  const actualIdCount = existingIds.length
  const enabled = actualIdCount === totalIdCount

  return useQuery(
    ["EVALUATION_TEMPLATES", templateIds],
    () => getTemplates(existingIds, enabled),
    // @ts-ignore
    {
      ...options,
      enabled,
    }
  )
}
