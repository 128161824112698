import classNames from "classnames"
import {
  ComponentProps,
  ComponentType,
  ReactNode,
  ForwardRefRenderFunction,
  forwardRef,
} from "react"

import { ChevronRight as ChevronRightIcon } from "assets/icons"
import Dropdown from "components/forms/Dropdown"
import { cn } from "utils"

function getPadding(size: TPaddingSizes) {
  switch (size) {
    case "md":
      return "px-3 py-2"
    case "l":
      return "px-5 py-3.5"
    default:
      return ""
  }
}

type TPaddingSizes = "md" | "l"

type TProps = {
  children: ReactNode
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  type?: "button" | "submit" | "reset" | undefined
  secondary?: boolean
  className?: string
  Icon?: ComponentType<ComponentProps<"svg">>
  iconClassName?: string
  iconPosition?: "left" | "right"
  disabled?: boolean
  split?: boolean
  menuItems?: ComponentProps<typeof Dropdown>["menuItems"]
  padding?: TPaddingSizes
  form?: string
}

const Button: ForwardRefRenderFunction<HTMLButtonElement, TProps> = (
  props,
  ref
) => {
  const {
    children,
    onClick,
    secondary,
    className = null,
    Icon,
    iconClassName = "",
    iconPosition = "left",
    disabled,
    split,
    menuItems,
    padding = "md",
    type = "button",
    ...other
  } = props

  const button = (
    <button
      ref={ref}
      type={type}
      className={cn([
        "group min-h-[40px]",
        "disabled:cursor-default disabled:bg-gray-200 disabled:hover:bg-gray-200",
        secondary ? "buttonSecondary" : "button",
        split ? "rounded-r-none" : null,
        getPadding(padding),
        className,
      ])}
      {...{ onClick, disabled }}
      {...other}
    >
      {Icon && iconPosition === "left" && (
        <Icon className={classNames(["icon mr-2", iconClassName])} />
      )}
      {children}
      {Icon && iconPosition === "right" && (
        <Icon className={classNames(["icon ml-2", iconClassName])} />
      )}
    </button>
  )

  if (split && menuItems) {
    return (
      <div className="relative inline-flex h-full">
        {button}
        <Dropdown
          button={{
            content: (
              <ChevronRightIcon className="icon rotate-90" aria-hidden="true" />
            ),
            className: classNames(["buttonDropdown"]),
            props: {
              disabled,
            },
          }}
          {...{ menuItems }}
        />
      </div>
    )
  }

  return button
}

export default forwardRef(Button)
