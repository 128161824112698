import * as React from "react"

function SvgUserManagement(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M95.16 99.347a8.383 8.383 0 1016.764 0 8.383 8.383 0 10-16.765 0z"
          strokeWidth={5.83333}
        />
        <path
          d="M110.664 66.908l2.473 8.12a5.547 5.547 0 006.557 3.81l8.237-1.908a7.484 7.484 0 017.122 12.402l-5.763 6.212a5.6 5.6 0 000 7.584l5.763 6.212a7.484 7.484 0 01-7.122 12.402l-8.237-1.914a5.56 5.56 0 00-6.527 3.839l-2.474 8.12a7.437 7.437 0 01-14.245 0l-2.502-8.12a5.56 5.56 0 00-6.557-3.81l-8.237 1.914a7.484 7.484 0 01-7.122-12.402l5.763-6.212a5.6 5.6 0 000-7.584l-5.763-6.212a7.484 7.484 0 017.122-12.402l8.237 1.908a5.547 5.547 0 006.557-3.81l2.473-8.12a7.437 7.437 0 0114.245-.029zM55.417 102.083h-52.5a40.833 40.833 0 0162.9-34.358M22.388 15.126a41.137 41.137 0 0029.628 12.582 41.107 41.107 0 0016.298-3.354"
          strokeWidth={5.83333}
        />
        <path
          d="M18.958 27.708a24.792 24.792 0 1049.584 0 24.792 24.792 0 10-49.584 0z"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgUserManagement
