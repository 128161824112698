import { z } from "zod"

export const IntegrationTypeSchema = z.enum(["PERSONIO", "IKEA"])
export type TIntegrationType = z.infer<typeof IntegrationTypeSchema>

export const ClientIdAndSecretSchema = z.object({
  clientId: z.string(),
  clientSecret: z.string(),
})

export type TClientIdAndSecret = z.infer<typeof ClientIdAndSecretSchema>

export const CredentialsSchema = z.union([
  ClientIdAndSecretSchema,
  ClientIdAndSecretSchema,
])

export type TCredentials = z.infer<typeof CredentialsSchema>

export const EmploymentTypeSchema = z.enum([
  "Festanstellung",
  "Temporaer",
  "Berufslehre",
  "Praktikum",
])
export type TEmploymentType = z.infer<typeof EmploymentTypeSchema>

export const GenderSchema = z.enum(["male", "female", "Male", "Female"])
export type TGender = z.infer<typeof GenderSchema>
