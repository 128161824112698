import * as React from "react"

function SendEmailEnvelope(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-0.5 -0.5 24 24"
      height="100%"
      width="100%"
    >
      <path
        d="M20.508333333333333 16.483333333333334a0.9583333333333334 0.9583333333333334 0 0 1 -0.9382083333333333 0.7666666666666667H5.711666666666667a0.9583333333333334 0.9583333333333334 0 0 1 -0.9382083333333333 -1.15l1.9962083333333336 -9.583333333333334a0.9583333333333334 0.9583333333333334 0 0 1 0.9382083333333333 -0.7666666666666667H21.5625a0.9583333333333334 0.9583333333333334 0 0 1 0.9382083333333333 1.15Z"
        fill="none"
        stroke="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        d="m7.117541666666667 5.953166666666666 4.903791666666667 5.281375000000001a1.9166666666666667 1.9166666666666667 0 0 0 2.504125 0.26545833333333335l7.757708333333334 -5.429916666666667"
        fill="none"
        stroke="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        d="m0.4791666666666667 6.708333333333334 3.8333333333333335 0"
        fill="none"
        stroke="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        d="m0.4791666666666667 9.583333333333334 2.875 0"
        fill="none"
        stroke="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        d="m0.4791666666666667 12.458333333333334 1.9166666666666667 0"
        fill="none"
        stroke="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </svg>
  )
}

export default SendEmailEnvelope
