import classnames, { ArgumentArray } from "classnames"
import { twMerge } from "tailwind-merge"

/**
 * Merges multiple class names into a single string.
 * Applies twMerge to the result.
 *
 * @param {...(string|null|undefined)} classNames - The class names to merge.
 * @returns {string} The merged class names.
 */
const cn = (...classNames: ArgumentArray): string =>
  twMerge(classnames(classNames))

// unique name for easier importing
export default cn
