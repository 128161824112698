import { Switch } from "@headlessui/react"
import { ReactNode, useState, memo, FC } from "react"
import { useTranslation } from "react-i18next"

import cn from "../../utils/classMerge"

const sharedIconStyles = cn(
  "absolute inset-0 grid place-items-center",
  "h-full w-full transition-opacity"
)

interface TProps {
  label?: ReactNode
  icons?: {
    on: ReactNode
    off: ReactNode
  }
  onChange?: (state: boolean) => void
  initiallyOn?: boolean
  disabled?: boolean
  className?: string
}

const Toggle: FC<TProps> = ({
  label,
  icons,
  onChange,
  initiallyOn,
  disabled,
  className,
}): JSX.Element => {
  const { t } = useTranslation("toggleComponent")

  const [isOn, setIsOn] = useState(initiallyOn || false)

  return (
    <Switch.Group
      className={cn("Toggle", "flex items-center", className)}
      as="div"
    >
      <Switch
        checked={isOn}
        onChange={(state: boolean) => {
          setIsOn(state)
          if (onChange != null) {
            onChange(state)
          }
        }}
        {...{ disabled }}
        className={cn(
          "relative inline-flex h-[24px] w-[44px]",
          "flex-shrink-0 rounded-full border-2",
          "border-transparent transition-colors duration-200 ease-in-out",
          "focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2",
          isOn ? "bg-electricBlue" : "bg-gray-300",
          disabled ? "cursor-not-allowed" : "cursor-pointer",
          disabled && isOn && "bg-clearSky",
          disabled && !isOn && "bg-gray-2"
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          className={cn(
            "relative inline-block h-5 w-5",
            "rounded-full bg-white ring-0",
            "transform transition duration-200 ease-in-out",
            isOn ? "translate-x-5" : "translate-x-0"
          )}
        >
          {icons && (
            <>
              <span
                className={cn(
                  isOn
                    ? "opacity-0 duration-100 ease-out"
                    : "opacity-100 duration-200 ease-in",
                  sharedIconStyles
                )}
                aria-hidden="true"
              >
                {icons.on}
              </span>
              <span
                className={cn(
                  isOn
                    ? "opacity-100 duration-200 ease-in"
                    : "opacity-0 duration-100 ease-out",
                  sharedIconStyles
                )}
                aria-hidden="true"
              >
                {icons.off}
              </span>
            </>
          )}
        </span>
      </Switch>

      <Switch.Label
        as="span"
        className={cn(
          "pl-3",
          disabled ? "cursor-not-allowed opacity-60" : "cursor-pointer"
        )}
      >
        <span className="font-semibold">
          {label || (isOn ? t("on") : t("off"))}
        </span>
      </Switch.Label>
    </Switch.Group>
  )
}

export default Toggle
export const MemoizedToggle = memo(Toggle)
