import { SVGProps } from "react"

type TProps = {
  svgProps?: SVGProps<SVGSVGElement>
  pathProps?: SVGProps<SVGPathElement>
}

function SvgAlertCircle(props: TProps): JSX.Element {
  const { svgProps, pathProps } = props

  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M134.167 68.897A65.112 65.112 0 0170 134.167 63.216 63.216 0 015.833 71.12 65.112 65.112 0 0170 5.833a63.222 63.222 0 0164.167 63.065zM70 81.696V40.863"
          strokeWidth={5.83333}
          {...pathProps}
        />
        <path
          d="M69.947 93.362a1.43 1.43 0 00-1.405 1.488 1.482 1.482 0 001.475 1.43h0a1.435 1.435 0 001.406-1.488A1.476 1.476 0 0070 93.362h-.03"
          strokeWidth={5.83333}
          {...pathProps}
        />
      </g>
    </svg>
  )
}

export default SvgAlertCircle
