export const locationKeys = {
  all: ["LOCATIONS"] as const,
  one: (id: number) => [...locationKeys.all, id] as const,
}

export const fontKeys = {
  all: ["FONTS"] as const,
}

export const companyKeys = {
  all: ["COMPANIES"],
  allAlt: ["ALT_COMPANIES"],
  oneAlt: (id?: number) => [...companyKeys.allAlt, id] as const,
} as const

export const wordTemplateKeys = {
  all: ["WORD_TEMPLATES"] as const,
}
