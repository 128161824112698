import { AxiosError } from "axios"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"

import { useResetPassword, useSystemInfo } from "hooks/data"
import useGenericForm from "hooks/useGenericForm"
import { operations } from "types/apiSchema"

import Button from "../elements/Button"
import TextInput from "../forms/TextInput"
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "../ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  FormServerMessage,
} from "../ui/form"

const regexpList = {
  upperCase: /(?=.*[A-Z])/,
  lowerCase: /(?=.*[a-z])/,
  number: /(?=.*[0-9])/,
  special: /(?=.*[!@#$%^&*()_+{}:"<>?|[\]\\;',./~])/,
  alwaysPass: /.*/,
}

type Payload =
  operations["Benutzer_PostResetPassword"]["parameters"]["body"]["dto"]

type Props = {
  open: boolean
  onOpenChange: (open: boolean) => void
  secret?: string
}
const PasswordModal: React.FC<Props> = ({ open, onOpenChange, secret }) => {
  const { t } = useTranslation("profileDrawer")
  const { t: tPass } = useTranslation("passwordModal")
  const { t: tN } = useTranslation([
    "userRoles",
    "shared",
    "forms",
    "toastComponent",
  ])

  const systemInfo = useSystemInfo()
  const { mutateAsync, isLoading } = useResetPassword()

  const useComplexPasswordRules = systemInfo.data?.UseComplexPasswordRules
  const minLength = useComplexPasswordRules ? 14 : 8

  const { submit, ...form } = useGenericForm({
    mode: "onSubmit",
    zodSchema: (z) =>
      z.object({
        currentPassword: z.string().min(1, tN("forms:required") ?? ""),
        newPassword: z
          .string({ required_error: tN("forms:required") ?? "" })
          .min(1, tN("forms:required") ?? "")
          .regex(
            regexpList.upperCase,
            tN("forms:passwordAtLeastOneUppercaseLetter") ?? ""
          )
          .regex(
            regexpList.lowerCase,
            tN("forms:passwordAtLeastOneLowercaseLetter") ?? ""
          )
          .regex(regexpList.number, tN("forms:passwordAtLeastOneDigit") ?? "")
          .regex(
            useComplexPasswordRules
              ? regexpList.special
              : regexpList.alwaysPass,
            tN("forms:passwordAtLeastOneSpecialCharacter") ?? ""
          )
          .min(
            minLength,
            tN("forms:passwordAtLeastThisLong", { length: minLength }) ?? ""
          ),
      }),

    onSubmit: ({ currentPassword, newPassword }) => {
      let payload: Payload = {
        NewPassword: newPassword,
      }
      if (secret) {
        payload = {
          ...payload,
          Secret: secret,
        }
      } else {
        payload = {
          ...payload,
          OldPassword: currentPassword,
        }
      }
      mutateAsync(payload)
        .then(() => {
          form.clearErrors()
          onOpenChange(false)
          toast.success(tN("toastComponent:changesSaved") ?? "")
        })
        .catch((e: AxiosError<{ Message: string }>) => {
          if (e.response?.data.Message === "WRONG_PASSWORD") {
            form.setError("currentPassword", {
              message: tN("forms:wrongCurrentPassword") ?? "",
            })
            return
          }
          form.setError("root", {
            message: e.response?.data.Message ?? "Error",
          })
        })
    },
    defaultValues: {
      currentPassword: secret ? "a" : "",
      newPassword: "",
    },
  })

  console.log(form.formState.errors)

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl">
        <Form {...form}>
          <form onSubmit={submit}>
            <DialogHeader title={tN("profileDrawer:changePassword")} />

            <div className="grid gap-4 py-8">
              {!secret && (
                <FormField
                  name="currentPassword"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <TextInput
                          {...field}
                          register={form.register}
                          label={t("currentPassword")}
                          id="currentPassword"
                          autoComplete="new-password"
                          type="password"
                          placeholder={t("passwordPlaceholder") ?? ""}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              <FormField
                name="newPassword"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <TextInput
                        {...field}
                        register={form.register}
                        data-cy="newPassword"
                        label={t("newPassword")}
                        id="newPassword"
                        autoComplete="new-password"
                        type="password"
                        placeholder={t("passwordPlaceholder") ?? ""}
                        withReveal
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {useComplexPasswordRules && (
                <div className="rounded-lg bg-ice px-4 py-2 text-sm">
                  {tPass("mustBeAtLeast")}
                  <ul className="list-disc pl-4">
                    <li>{tPass("uppercaseLetters")}</li>
                    <li>{tPass("lowercaseLetters")}</li>
                    <li>{tPass("numbers")}</li>
                    <li>{tPass("specialCharacters")}</li>
                  </ul>
                  <br />
                  <p>{tPass("personalDataNotAllowed")}</p>
                  <br />
                  <p>{tPass("companyRelatedTermsNotAllowed")}</p>
                  <br />
                  <p>{tPass("patternsOrSequencesNotAllowed")}</p>
                  <br />
                  <p>{tPass("dictionaryTermsNotAllowed")}</p>
                  <br />
                  <p>{tPass("simpleCombinationsNotAllowed")}</p>
                  <br />
                  {tPass("passwordsMustBeUnique")}
                </div>
              )}
            </div>

            <FormServerMessage />

            <DialogFooter>
              <Button
                secondary
                onClick={() => {
                  onOpenChange(false)
                }}
              >
                {tN("shared:close")}
              </Button>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? tN("shared:submitting") : tN("shared:submit")}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default PasswordModal
