import * as _ from "lodash-es"
import posthog from "posthog-js"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { fetchJson } from "utils/network"

type TData = void
type TError = unknown
type TPayload = {
  employeeNumber?: string
  fullName: string
  dateOfBirth: string
  lineManager?: string
  certificateTypeID: number
  languageID: string
  comment?: string
}
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

export const useRequestCertificate: THook = (options) => {
  const { t } = useTranslation("toastComponent")
  const { t: tM } = useTranslation("login", {
    keyPrefix: "requestCertificateModal",
  })

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(
    (payload) =>
      fetchJson<TData>("enhancedcertificaterequest", {
        method: "POST",
        body: payload,
      }),
    {
      onMutate(payload) {
        toastId = toast.loading(t("inProgress"))

        void posthog.capture("User requested certificate")

        options?.onMutate?.(payload)
      },
      onError(...args) {
        toast.error(t("error"), { id: toastId })

        void options?.onError?.(...args)
      },
      onSuccess(...args) {
        toast.success(tM("requestSent"), { id: toastId, duration: 1000 * 5 })

        void options?.onSuccess?.(...args)
      },
      ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
    }
  )
}
