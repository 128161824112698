import * as React from "react"

function SvgPlus(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M70 5.833v128.334M134.167 70H5.833" strokeWidth={5.83333} />
      </g>
    </svg>
  )
}

export default SvgPlus
