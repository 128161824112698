import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { IEvaluationTemplate } from "pages/text-components/evaluations"
import { fetchJson } from "utils/network"

type TData = IEvaluationTemplate
type TError = unknown
type TVariable = number
type THook = (
  options?: UseMutationOptions<TData, TError, TVariable>
) => UseMutationResult<TData, TError, TVariable>

export const useEvaluationTemplateDeferred: THook = (options) =>
  // @ts-ignore
  useMutation((id) => fetchJson<TData>(`CatSatzTemplate/${id}`), options)
