import posthog from "posthog-js"
import { useState, useEffect, useRef, useContext, createContext } from "react"
import type { ReactNode } from "react"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationResult } from "react-query"

import { fetchBlob } from "utils/network"

type TDownloadCertificatePayload = {
  type: "PDF" | "DOCX"
  certificateId: number
  completed: boolean
  customName?: string
}

type TContext = {
  downloadCertificate: UseMutationResult<
    { blob: Blob; fileName?: string },
    unknown,
    TDownloadCertificatePayload
  >
  downloadTemplate: UseMutationResult<
    { blob: Blob; fileName?: string },
    unknown,
    number
  >
}

const DownloadContext = createContext<TContext | undefined>(undefined)

type TProps = {
  children: ReactNode
}

function DownloadProvider({ children }: TProps): JSX.Element {
  const { t } = useTranslation("toastComponent")

  const downloadLinkElem = useRef<HTMLAnchorElement>(null)

  const [downloadLink, setDownloadLink] = useState({
    url: "",
    fileName: "",
  })

  let downloadCertificateToastId: ReturnType<typeof toast.loading>
  // REFACTOR:
  // Extract this logic into custom data hook.
  const downloadCertificate = useMutation<
    { blob: Blob; fileName?: string },
    unknown,
    TDownloadCertificatePayload
  >(
    ({ certificateId, type }) =>
      // @ts-ignore
      fetchBlob(`Zeugnisse/${certificateId}/Dokument`, {
        queryParams: { dokumentTyp: type },
      }),
    {
      onMutate() {
        downloadCertificateToastId = toast.loading(t("downloading"))
      },
      onError() {
        toast.error(t("error"), { id: downloadCertificateToastId })
      },
      onSuccess(
        { blob, fileName },
        { certificateId, type, completed, customName }
      ) {
        const generatedBlob = new Blob([blob], {
          type:
            type === "PDF"
              ? "application/pdf"
              : "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        })
        const url = window.URL.createObjectURL(generatedBlob)
        const defaultName = `Zeugnis.${type.toLowerCase()}`

        setDownloadLink({
          fileName:
            customName ??
            (fileName?.includes(".pdf") || fileName?.includes(".docx")
              ? fileName
              : defaultName),
          url,
        })

        toast.success(t("downloadComplete"), { id: downloadCertificateToastId })

        void posthog.capture("Certificate downloaded", {
          certificateId,
          fileType: type,
          completed,
        })

        if (type === "DOCX") {
          void posthog.capture("Certificate downloaded - docx", {
            certificateId,
            completed,
          })
        }
      },
    }
  )

  let downloadTemplateToastId: ReturnType<typeof toast.loading>
  // REFACTOR:
  // Extract this logic into custom data hook.
  const downloadTemplate = useMutation<
    { blob: Blob; fileName?: string },
    unknown,
    number
    // @ts-ignore
  >((id) => fetchBlob(`ZeugnisTemplate/${id}/FileData`), {
    onMutate() {
      downloadTemplateToastId = toast.loading(t("downloading"))
    },
    onError() {
      toast.error(t("error"), { id: downloadTemplateToastId })
    },
    onSuccess({ blob, fileName }) {
      const generatedBlob = new Blob([blob], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      })
      const url = window.URL.createObjectURL(generatedBlob)

      setDownloadLink({
        fileName: fileName ?? `SkribaZeugnisTemplate.dotx`,
        url,
      })

      toast.success(t("downloadComplete"), { id: downloadTemplateToastId })
    },
  })

  useEffect(() => {
    if (
      downloadLink.url !== "" &&
      downloadLink.fileName !== "" &&
      downloadLinkElem.current
    ) {
      downloadLinkElem.current.click()
    }
  }, [downloadLink])

  return (
    <DownloadContext.Provider
      value={{
        downloadCertificate,
        downloadTemplate,
      }}
    >
      {children}
      <a
        className="hidden"
        ref={downloadLinkElem}
        href={downloadLink.url}
        {...(downloadLink.fileName && {
          download: downloadLink.fileName,
        })}
        onClick={() => {
          // Firefox: it's necessary to delay revoking the ObjectURL.
          setTimeout(() => {
            window.URL.revokeObjectURL(downloadLink.url)
          }, 500)
        }}
      >
        Download {downloadLink.fileName}
      </a>
    </DownloadContext.Provider>
  )
}

function useDownloadContext(): TContext {
  const ctx = useContext(DownloadContext)

  if (!ctx) {
    throw new Error("useDownloadContext must be used within DownloadProvider.")
  }

  return ctx
}

export { useDownloadContext, DownloadProvider }
