import * as React from "react"

function SvgFilter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      {...props}
    >
      <g transform="scale(5.83333)">
        <defs>
          <style>
            {
              ".filter_svg__a{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round}"
            }
          </style>
        </defs>
        <path
          className="filter_svg__a"
          d="M.5 4.534h23M2.95 9.786h18.1M8.647 20.291h6.706M5.399 15.038h13.202"
        />
      </g>
    </svg>
  )
}

export default SvgFilter
