import { AxiosError } from "axios"
import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationResult, UseMutationOptions } from "react-query"

import { EGenders } from "src/constants"
import { fetchJson } from "utils/network"

import { IEmployee, IJob } from "."

type TData = IEmployee
type TError = AxiosError<{ Message: string }>
type TPayload = Pick<
  IEmployee,
  "Geburtsdatum" | "Heimatort" | "Nachname" | "Vorname" | "Personalnummer"
> & {
  CatAnrede: {
    ID: EGenders
  }
  Beschaeftigungen: Omit<
    IJob,
    "Aufgaben" | "BeschaeftigungID" | "HasUsedAufgaben"
  >[]
}
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

const createEmployee = (payload: TPayload) =>
  // @ts-ignore
  fetchJson<TData>(`mitarbeiter`, { method: "POST", body: payload })

export const useCreateEmployee: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(createEmployee, {
    onMutate(...args) {
      toastId = toast.loading(t("inProgress"))

      options?.onMutate?.(...args)
    },
    onError(...args) {
      const errorId = args[0].response?.data?.Message
      if (errorId === "PERSONALNUMMER_EXISTS") {
        toast.error(t("errors.employeeIdExist"), { id: toastId })
      } else {
        toast.error(t("error"), { id: toastId })
      }

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("done"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
