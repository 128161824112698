import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query"

import { fetchJson } from "utils/network"

import { certificateKeys } from "./keys"
import { ICertificate, ITask } from "./useCertificate"

type TData = {
  ZeugnisID: number
  Aufgaben: ITask[]
  AufgabenSectionText: {
    IntroText: string
    Tasks: {
      TaskId: number
      JobId: number
      Text: string
    }[]
  }[]
}

type TError = {
  Message: string
}

type TPayload = {
  certificateId: number
  payload: {
    Aufgaben: ITask[]
  }
}

type TContext = { previousCertificate?: ICertificate }
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload, TContext>
) => UseMutationResult<TData, TError, TPayload, TContext>

export const useTasksMutation: THook = (options) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(
    ({ certificateId, payload }) =>
      // @ts-ignore
      fetchJson<TData>(`Zeugnisse/${certificateId}/PutTasks`, {
        method: "PUT",
        body: payload,
      }),
    {
      onMutate({ certificateId, payload }) {
        toastId = toast.loading(t("updating"))

        const key = certificateKeys.one(certificateId)

        const previousCertificate = queryClient.getQueryData<ICertificate>(key)

        if (previousCertificate) {
          queryClient.setQueryData(key, {
            ...previousCertificate,
            Aufgaben: payload.Aufgaben,
          })
        }

        return { previousCertificate }
      },
      onError(...args) {
        const [, { certificateId }, ctx] = args

        if (ctx?.previousCertificate) {
          queryClient.setQueryData(
            certificateKeys.one(certificateId),
            ctx.previousCertificate
          )
        }

        toast.error(t("error"), { id: toastId })

        void options?.onError?.(...args)
      },
      onSuccess(...args) {
        const [data] = args

        const key = certificateKeys.one(data.ZeugnisID)
        const previousCertificate = queryClient.getQueryData<ICertificate>(key)

        queryClient.setQueryData(key, {
          ...previousCertificate,
          ...data,
        })
        toast.success(t("changesSaved"), { id: toastId })

        void options?.onSuccess?.(...args)
      },
      ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
    }
  )
}
