import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { EOrganizationTypes } from "src/constants"
import { fetchJson } from "utils/network"

import { orgUnitsKeys } from "./keys"

export type TOrgUnitShort = {
  CatMLTextID: number
  Code: EOrganizationTypes
  de: string
  fr: string
  it: string
  en: string
}

type TData = TOrgUnitShort[]
type THook = (options?: UseQueryOptions<TData>) => UseQueryResult<TData>

export const useOrgUnits: THook = (options) =>
  useQuery(
    orgUnitsKeys.all,
    // @ts-ignore
    () => fetchJson<TData>("organizationtypes"),
    // @ts-ignore
    options
  )
