import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { fetchJson } from "utils/network"

import { TSystemInfo } from "./useSystemInfo"

type TData = TSystemInfo
type TError = unknown
type TPayload = TSystemInfo
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

const mutateSystemInfo = (payload: TPayload) =>
  fetchJson<TData>("system", {
    method: "PUT",
    body: payload,
  })

export const useSystemInfoMutation: THook = (options) => {
  const { t } = useTranslation(`toastComponent`)

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(mutateSystemInfo, {
    onMutate(payload) {
      toastId = toast.loading(t("updating"))

      options?.onMutate?.(payload)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("changesSaved"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
