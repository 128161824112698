import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query"

import { fetchJson } from "utils/network"

import { orgUnitsKeys } from "./keys"
import { TOrgUnit } from "./useOrgUnit"
import { TOrgUnitShort } from "./useOrgUnits"

type TData = void
type TError = unknown
type TPayload = TOrgUnit
type TContext = {
  previousOrgUnit?: TOrgUnit
  previousOrgUnits?: TOrgUnitShort[]
}
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload, TContext>
) => UseMutationResult<TData, TError, TPayload, TContext>

export const useUpdateOrgUnit: THook = (options) => {
  const queryClient = useQueryClient()
  const keyAll = orgUnitsKeys.all
  let keyOne: ReturnType<typeof orgUnitsKeys.one>

  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(
    (payload) => {
      keyOne = orgUnitsKeys.one(payload.Id)

      // @ts-ignore
      return fetchJson<TData>(`OrganizationTypes/${payload.Id}`, {
        method: "PUT",
        body: payload,
      })
    },
    {
      async onMutate(payload) {
        toastId = toast.loading(t("updating"))

        await Promise.all([
          queryClient.cancelQueries(keyOne),
          queryClient.cancelQueries(keyAll),
        ])

        const previousOrgUnit = queryClient.getQueryData<TOrgUnit>(keyOne)
        const previousOrgUnits =
          queryClient.getQueryData<TOrgUnitShort[]>(keyAll)

        queryClient.setQueryData(keyOne, payload)
        if (previousOrgUnits) {
          queryClient.setQueryData(
            keyAll,
            previousOrgUnits.map((orgUnit) =>
              orgUnit.CatMLTextID === payload.Id ? payload.NameML : orgUnit
            )
          )
        }

        return { previousOrgUnit, previousOrgUnits }
      },
      onError(...args) {
        const [, , context] = args

        if (context?.previousOrgUnit) {
          queryClient.setQueryData(keyOne, context.previousOrgUnit)
        }
        if (context?.previousOrgUnits) {
          queryClient.setQueryData(keyAll, context.previousOrgUnits)
        }

        toast.error(t("error"), { id: toastId })

        void options?.onError?.(...args)
      },
      onSuccess(...args) {
        const [, payload, context] = args

        queryClient.setQueryData(keyOne, payload)

        if (context?.previousOrgUnits) {
          queryClient.setQueryData(
            keyAll,
            context.previousOrgUnits.map((orgUnit) =>
              orgUnit.CatMLTextID === payload.Id ? payload.NameML : orgUnit
            )
          )
        }

        toast.success(t("changesSaved"), { id: toastId })

        void options?.onSuccess?.(...args)
      },
      ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
    }
  )
}
