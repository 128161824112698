import { useQuery, UseQueryResult } from "react-query"

import { fetchJson } from "utils/network"

import { IFunction } from "."
import { functionKeys } from "./keys"

type TData = IFunction["InitSettings"]
type THook = () => UseQueryResult<TData>

export const useFunctionInitSettings: THook = () =>
  useQuery(functionKeys.initSettings(), () =>
    // @ts-ignore
    fetchJson<TData>(`catfunktionen/getinitsettings`)
  )
