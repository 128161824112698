import { Cancel as CancelIcon } from "assets/icons"
import cn from "utils/classMerge"

interface Props {
  label: string
  onClick?: () => void
  close?: boolean
  disabled?: boolean
}

export const PillClickable = ({ label, onClick, close, disabled }: Props) => {
  return (
    <button
      disabled={disabled}
      className={cn(
        "inline-flex items-center rounded-full bg-blue-300",
        "px-2.5 py-0.5 text-sm font-medium text-gray-800",
        onClick && !disabled && "cursor-pointer hover:opacity-80",
        disabled && "cursor-not-allowed"
      )}
      onClick={onClick}
    >
      <span className="truncate">{label}</span>
      {close && !disabled && (
        <div
          className={cn(
            "ml-1.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center",
            "rounded-full text-black hover:opacity-80 focus:outline-none",
            "focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          )}
        >
          <span className="sr-only">Remove</span>
          <CancelIcon className="h-2 w-2" aria-hidden="true" />
        </div>
      )}
    </button>
  )
}

export default PillClickable
