import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
  useQueryClient,
} from "react-query"

import { IUser } from "types"
import { fetchJson } from "utils/network"

import { CURRENT_USER_KEY } from "./useCurrentUser"

type TData = IUser
type TError = unknown
type TPayload = IUser
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

const mutateCurrentUser = (payload: TPayload) =>
  fetchJson<TData>("benutzer/current", {
    method: "PUT",
    body: payload,
  })

export const useCurrentUserMutation: THook = (options) => {
  const queryClient = useQueryClient()

  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(mutateCurrentUser, {
    onMutate(payload) {
      toastId = toast.loading(t("updating"))

      options?.onMutate?.(payload)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      const [data] = args

      queryClient.setQueryData(CURRENT_USER_KEY, data)

      toast.success(t("changesSaved"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
