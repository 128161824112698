import * as React from "react"

function SvgDownload(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M72.917 2.91v99.167M96.25 78.744l-23.333 23.333-23.334-23.333M2.917 137.077h134.166"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgDownload
