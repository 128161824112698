import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { fetchJson } from "utils/network"

import { employeeKeys } from "."
import {
  TEmployeeForTable,
  TEmployeesForTableQueryParams,
} from "./useEmployeesForTableInfinite"

type TData = TEmployeeForTable[]
type THook = (
  params?: TEmployeesForTableQueryParams,
  options?: UseQueryOptions<TData>
) => UseQueryResult<TData>

function getEmployeesForTable(params?: TEmployeesForTableQueryParams) {
  return fetchJson<TData, false, TEmployeesForTableQueryParams>(
    "mitarbeiter/getemployees",
    {
      queryParams: params,
    }
  )
}

export const useEmployeesForTable: THook = (params, options) =>
  useQuery(
    employeeKeys.allForTable(params),
    () => getEmployeesForTable(params),
    // @ts-ignore
    options
  )
