import * as React from "react"

function SvgEmployees(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M2.917 119.583a40.833 40.833 0 0181.666 0zM81.632 38.255a40.967 40.967 0 0022.884 6.953 41.107 41.107 0 0016.298-3.354"
          strokeWidth={5.83333}
        />
        <path
          d="M77.35 29.167a24.786 24.786 0 114.317 36.102 25.556 25.556 0 01-2.917-2.502M84.583 80.442a40.862 40.862 0 0152.5 39.141H99.167M22.388 32.62a41.195 41.195 0 0045.926 9.234"
          strokeWidth={5.83333}
        />
        <path
          d="M18.958 45.208a24.792 24.792 0 1049.584 0 24.792 24.792 0 10-49.584 0z"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgEmployees
