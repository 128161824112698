import { zodResolver } from "@hookform/resolvers/zod"
import {
  DefaultValues,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form"
import * as z from "zod"

type FormShape<T extends z.ZodRawShape> = z.infer<z.ZodObject<T>>

type PropsWithZod<T extends z.ZodRawShape> = {
  zodSchema: (zod: typeof z) => z.ZodObject<T>
  onSubmit: SubmitHandler<FormShape<T>>
  onSubmitError?: SubmitErrorHandler<FormShape<T>>
  defaultValues?: DefaultValues<FormShape<T>>
  mode?: "onBlur" | "onChange" | "onSubmit" | "onTouched" | "all" | undefined
}

type PropsWithoutZod<T extends z.ZodRawShape> = {
  zodSchema: z.ZodObject<T>
  onSubmit: SubmitHandler<FormShape<T>>
  onSubmitError?: SubmitErrorHandler<FormShape<T>>
  defaultValues?: DefaultValues<FormShape<T>>
  mode?: "onBlur" | "onChange" | "onSubmit" | "onTouched" | "all" | undefined
}

type Props<T extends z.ZodRawShape> = PropsWithZod<T> | PropsWithoutZod<T>

const useGenericForm = <T extends z.ZodRawShape>({
  zodSchema,
  onSubmit,
  onSubmitError,
  defaultValues,
  mode = "onChange",
}: Props<T>) => {
  const zod = typeof zodSchema === "function" ? zodSchema(z) : zodSchema
  type FormValues = z.infer<typeof zod>

  const form = useForm<FormValues>({
    mode,
    resolver: zodResolver(zod),
    defaultValues,
  })

  const handleOnSubmit = (data: FormValues) => {
    form.clearErrors()
    onSubmit(data)
  }

  const submit = form.handleSubmit(handleOnSubmit, onSubmitError)

  return {
    ...form,
    submit,
    values: form.getValues(),
  }
}

export default useGenericForm
