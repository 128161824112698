import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { IFunction } from "hooks/data"
import { EGenders, ELanguages } from "src/constants"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

export const DEFAULT_REQUEST_FUNCTION_PARAMS = {
  renderGeschlechtId: EGenders.FEMALE as unknown as number,
  renderSpracheId: ELanguages.DE,
} as const

type TQueryParams = operations["CatFunktionen_Put"]["parameters"]["query"]

type TData = IFunction
type TError = unknown
type TPayload = IFunction
type TArgs = {
  params?: Partial<TQueryParams>
  options?: UseMutationOptions<TData, TError, TPayload>
}
type THook = (args?: TArgs) => UseMutationResult<TData, TError, TPayload>

const updateFunction = (payload: TPayload, params?: Partial<TQueryParams>) =>
  fetchJson<TData, false, TQueryParams>(
    // @ts-ignore
    `CatFunktionen/${payload.CatFunktionID}`,
    {
      queryParams: {
        ...DEFAULT_REQUEST_FUNCTION_PARAMS,
        ...params,
      },
      method: "PUT",
      body: payload,
    }
  )

export const useFunctionMutation: THook = ({ params, options } = {}) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation((payload) => updateFunction(payload, params), {
    onMutate(...args) {
      toastId = toast.loading(t("updating"))

      options?.onMutate?.(...args)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("changesSaved"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
