import * as React from "react"

function SvgGarbage(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M119.583 20.417V131.25a5.833 5.833 0 01-5.833 5.833h-87.5a5.833 5.833 0 01-5.833-5.833V20.417M43.75 20.417V8.75a5.833 5.833 0 015.833-5.833h40.834A5.833 5.833 0 0196.25 8.75v11.667M2.917 20.417h134.166M43.75 40.833v70M70 40.833v70M96.25 40.833v70"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgGarbage
