import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { TODataParams } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { TTask } from "./useTasksInfinite"

type TQueryParams = operations["Tasks_GetList"]["parameters"]["query"] &
  TODataParams

type TData = TTask[]
type TArgs = {
  params?: TQueryParams
  options?: UseQueryOptions<TData>
}
type THook = (args?: TArgs) => UseQueryResult<TData>

export const useTasks: THook = ({ params, options } = {}) =>
  useQuery(
    ["TASKS", params],
    () =>
      fetchJson<TData, false, TQueryParams>("tasks", { queryParams: params }),
    // @ts-ignore
    options
  )
