import * as _ from "lodash-es"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { UseMutationOptions, UseMutationResult, useMutation } from "react-query"

import { fetchJson } from "utils/network"

import { ICertificateType } from "./useCertificateTypes"

type TData = ICertificateType
type TError = unknown
type TPayload = ICertificateType
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

export const useCertificateTypeMutation: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(
    (payload) =>
      // @ts-ignore
      fetchJson<TData>(`CatZeugnisTypen/${payload.CatZeugnisTypID}`, {
        method: "PUT",
        body: payload,
      }),
    {
      onMutate(payload) {
        toastId = toast.loading(t("updating"))

        options?.onMutate?.(payload)
      },
      onError(...args) {
        toast.error(t("error"), { id: toastId })

        void options?.onError?.(...args)
      },
      onSuccess(...args) {
        toast.success(t("changesSaved"), { id: toastId })

        void options?.onSuccess?.(...args)
      },
      ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
    }
  )
}
