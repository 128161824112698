import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { ITraining, ISignature } from "context/CertificateContext"
import { EGenders } from "src/constants"
import { TDateFormat } from "types"
import { fetchJson } from "utils/network"

import { employeeKeys } from "./keys"
import { IJob } from "./useEmployeeResponsibilityMutation"
import { ICertificate } from "../certificate"

export type TCertificateReduced = Pick<
  ICertificate,
  | "AktuellerZeugnisBearbeiter"
  | "CatBearbeitungsStatus"
  | "CatZeugnisTyp"
  | "Note"
  | "ModDate"
  | "VerantwortlicherHRBenutzer"
  | "ZeugnisID"
> & {
  CatZeugnisTypKuerzel: string
  HasFreierBearbeitungstext: boolean
  HasTermin: boolean
  TerminOrModDate: string
}

export interface IEmployee {
  MitarbeiterID: number
  ModDate: string
  Beschaeftigungen: IJob[] | []
  Vorname: string
  Nachname: string
  EigeneZeugnisse: TCertificateReduced[]
  CatAnrede: {
    ID: EGenders
    DisplayName: string
  }
  Heimatort: string
  Geburtsdatum: TDateFormat | null
  Eintrittsdatum: TDateFormat
  Austrittsdatum: TDateFormat | null
  Ausbildungen: ITraining[] | []
  Personalnummer: string
  Unterschriftsberechtigungen: ISignature[] | []
}

type TData = IEmployee
type THook = (
  id?: number,
  options?: UseQueryOptions<TData>
) => UseQueryResult<TData>

const getEmployee = (id?: number) =>
  id
    ? // @ts-ignore
      fetchJson<TData>(`Mitarbeiter/${id}`)
    : Promise.reject(new Error("Missing id"))

export const useEmployee: THook = (id, options) =>
  // @ts-ignore
  useQuery(employeeKeys.one(id), () => getEmployee(id), {
    ...options,
    enabled: Boolean(id),
  })
