import { AxiosResponse } from "axios"
import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from "react-query"

import { TODataParams } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { certificateKeys, ECertificateStatuses } from "."

export type TCertificateForTable = {
  CertificateID: number
  ModDate: string
  EmployeeID: number
  EmployeeName: string
  EmployeePersonalNumber: string
  StatusID: number
  StatusName: string
  StatusCode: ECertificateStatuses
  TypeID: number
  TypeName: string
  ResponsibleHRID: number
  ResponsibleHRName: string
  EditorID: number | null
  EditorName: string
  LineManagerID?: number
  LineManagerName?: string
  LocalHRID?: number
  LocalHRName?: string
  Termin: string
}

export type TCertificatesForTableQueryParams = TODataParams &
  operations["Mitarbeiter_GetCertificates"]["parameters"]["query"]

type TData = TCertificateForTable[]
type TResponse = AxiosResponse<TData>
type THook = (
  params?: TCertificatesForTableQueryParams,
  options?: UseInfiniteQueryOptions<TResponse>
) => UseInfiniteQueryResult<TResponse>
type TGetCertificatesForTableParams = {
  pageParam?: {
    recordsToSkip: number
  }
  queryKey: [string, TCertificatesForTableQueryParams]
}

function getCertificatesForTable({
  pageParam = {
    recordsToSkip: 0,
  },
  queryKey,
}: TGetCertificatesForTableParams) {
  const [, queryParams] = queryKey
  const { recordsToSkip } = pageParam

  return fetchJson<TData, true, TCertificatesForTableQueryParams>(
    "zeugnisse/getcertificates",
    {
      fullResponse: true,
      queryParams: {
        ...queryParams,
        $skip: recordsToSkip,
        $inlinecount: "allpages",
      },
    }
  )
}

export const useCertificatesForTableInfinite: THook = (params, options) =>
  useInfiniteQuery(
    certificateKeys.allForTableInfinite(params),
    // @ts-ignore
    getCertificatesForTable,
    {
      getNextPageParam: (lastPage, allPages) => {
        const proxiedUrl = new URL(
          lastPage.config.url ?? "",
          window.location.href
        )
        const url = proxiedUrl.searchParams.get("originalUrl")
          ? new URL(
              proxiedUrl.searchParams.get("originalUrl") ?? "",
              window.location.href
            )
          : proxiedUrl
        const hasRequestedTotalCount = url.searchParams.has("$inlinecount")

        if (!hasRequestedTotalCount) {
          console.error(
            "useCertificatesForTableInfinite: The total count was not requested in the query. This is required for infinite queries."
          )
          return undefined
        }

        const recordsToSkip = allPages.reduce((accumulator, page) => {
          accumulator += page.data.length
          return accumulator
        }, 0)

        if (recordsToSkip >= Number(lastPage.headers.totalcount))
          return undefined

        return { recordsToSkip }
      },
      ...options,
    }
  )
