import * as React from "react"

function SvgChevronLeft(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M107.333 2.917L33.98 67.818a2.917 2.917 0 000 4.364l73.354 64.901"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={5.83333}
      />
    </svg>
  )
}

export default SvgChevronLeft
