import * as RadioGroupPrimitive from "@radix-ui/react-radio-group"
import { Circle } from "lucide-react"
import * as React from "react"

import { Pencil as PencilIcon } from "assets/icons"
import { cn } from "utils"

import { FormItem, FormControl } from "./form"
import { Label } from "./label"

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("grid gap-0", className)}
      {...props}
      ref={ref}
    />
  )
})
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        "aspect-square h-4 w-4 rounded-full border border-primary text-blue-700",
        "ring-offset-background focus:outline-none focus-visible:ring-2",
        "focus-visible:ring-ring focus-visible:ring-offset-2",
        "peer disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
        <Circle className="h-2.5 w-2.5 fill-current text-current" />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  )
})
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

const radioItemWrapperClass = cn("flex items-center gap-x-2 pt-0.5")
const radioLabelClass = cn("cursor-pointer text-sm font-normal")

type RadioFormRowProps = {
  children: React.ReactNode
  className?: string
  label: string
  active?: boolean
  htmlFor?: string
}
const RadioFormRow = ({
  children,
  className,
  label,
  active,
  htmlFor,
}: RadioFormRowProps) => {
  return (
    <FormItem className={cn(radioItemWrapperClass, className)}>
      <FormControl>{children}</FormControl>
      <Label
        htmlFor={htmlFor}
        className={cn(radioLabelClass, active && "font-semibold")}
      >
        {label}
      </Label>
    </FormItem>
  )
}

const RadioItem = ({
  label,
  htmlFor,
  value,
  className,
  editable,
  id,
  onEditButtonClick
}: {
  label: string
  htmlFor: string
  value: string
  className?: string
  editable?: boolean
  id?: string
  onEditButtonClick?: (id: string ) => void
}) => {

  return (
    <div className={cn(radioItemWrapperClass, className)}>
      <RadioGroupItem id={htmlFor} value={value} />
      <Label
        htmlFor={htmlFor}
        className={cn(
          radioLabelClass,
          "peer-data-[state=checked]:font-semibold"
        )}
      >
        {label}
      </Label>
      {editable && id && onEditButtonClick && (
        <div
          role="button"
          tabIndex={0}
          title="edit option"
          className="-m-2 ml-auto p-2"
          onClick={() => onEditButtonClick(id)}
          onKeyDown={() => onEditButtonClick(id)}
        >
          <PencilIcon className="icon" />
        </div>
      )}
    </div>
  )
}

export { RadioFormRow, RadioGroup, RadioGroupItem, RadioItem }
