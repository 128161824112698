import * as React from "react"

function SvgLocked(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M58.333 93.508a11.667 11.667 0 1023.334 0 11.667 11.667 0 10-23.334 0z"
          strokeWidth={5.83333}
        />
        <path
          d="M119.583 78.925A23.333 23.333 0 0096.25 55.592h-52.5a23.333 23.333 0 00-23.333 23.333v35a23.333 23.333 0 0023.333 23.333h52.5a23.333 23.333 0 0023.333-23.333z"
          strokeWidth={5.83333}
        />
        <path
          d="M37.917 56.327V35.175a32.083 32.083 0 0164.166 0v21.152"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgLocked
