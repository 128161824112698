import * as React from "react"

function SvgEditCertificate(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M26.25 49.583h55.417M26.25 67.083h55.417M26.25 32.083h40.833M26.25 84.583H70M26.25 102.083h23.333M49.583 137.083H8.75a5.833 5.833 0 01-5.833-5.833V8.75A5.833 5.833 0 018.75 2.917h77.542a5.833 5.833 0 014.125 1.709L112.04 26.25a5.833 5.833 0 011.709 4.124v19.21M91.583 130.083l-24.5 7 7-24.5 41.878-41.877a12.374 12.374 0 0117.5 17.5zM110.71 75.956l17.5 17.5M74.083 112.583l17.5 17.5"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgEditCertificate
