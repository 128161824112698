import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationResult, UseMutationOptions } from "react-query"

import { fetchJson } from "utils/network"

type TData = undefined
type TError = unknown
type TPayload = number
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

const deleteEmployee = (id: TPayload) =>
  // @ts-ignore
  fetchJson<TData>(`Mitarbeiter/${id}`, { method: "DELETE" })

export const useDeleteEmployee: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation<TData, TError, TPayload>(deleteEmployee, {
    onMutate(...args) {
      toastId = toast.loading(t("inProgress"))

      options?.onMutate?.(...args)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("done"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
