import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { UseMutationOptions, UseMutationResult, useMutation } from "react-query"

import { EGenders } from "src/constants"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { ICertificateLanguage } from "./useCertificateLanguages"
import { ICertificateType } from "./useCertificateTypes"
import { IEvaluationCategory } from "./useEvaluationCategories"

type TQueryParams = operations["CatBereiche_Put"]["parameters"]["query"]

type TData = IEvaluationCategory
type TError = unknown
type TVariables = {
  category: IEvaluationCategory
  gender: EGenders
  certificateType: ICertificateType
  language: ICertificateLanguage
}
type THook = (
  options?: UseMutationOptions<TData, TError, TVariables>
) => UseMutationResult<TData, TError, TVariables>

const updateEvaluationCategory = ({
  category,
  gender,
  certificateType,
  language,
}: TVariables) =>
  fetchJson<TData, false, TQueryParams>(
    // @ts-ignore
    `CatBereiche/${category.CatBereichID}`,
    {
      method: "PUT",
      body: category,
      queryParams: {
        renderZeugnisTypId: certificateType.CatZeugnisTypID,
        renderGeschlechtId: gender as unknown as number,
        renderSpracheId: language.CatSpracheID,
      },
    }
  )

export const useEvaluationCategoryMutation: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(updateEvaluationCategory, {
    onMutate(payload) {
      toastId = toast.loading(t("updating"))

      options?.onMutate?.(payload)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("changesSaved"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
