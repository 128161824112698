import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query"

import { fetchJson } from "utils/network"

import { certificateKeys } from "./keys"
import { ECertificateStatuses, ICertificate } from "./useCertificate"

type TData = ICertificate
type TError = {
  Message: string
}
type TPayload = Omit<ICertificate, "CatBearbeitungsStatus"> & {
  CatBearbeitungsStatus: {
    Code: ECertificateStatuses
  }
  toastMessage?: string
  toastConfig?: {
    enabled?: boolean
  }
}
type TContext = { previousCertificate?: ICertificate }
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload, TContext>,
  toastMessage?: string
) => UseMutationResult<TData, TError, TPayload, TContext>

const updateCertificate = (payload: TPayload) =>
  // @ts-ignore
  fetchJson<TData>(`Zeugnisse/${payload.ZeugnisID}`, {
    method: "PUT",
    body: payload,
  })

export const useCertificateMutation: THook = (options) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation("toastComponent")
  let toastId: ReturnType<typeof toast.loading>
  let toastMessage: string

  return useMutation(updateCertificate, {
    async onMutate(payload) {
      if (payload?.toastMessage) toastMessage = payload.toastMessage

      const shouldShowToast = payload.toastConfig?.enabled ?? true
      if (shouldShowToast) {
        toastId = toast.loading(t("updating"))
      }

      const key = certificateKeys.one(payload.ZeugnisID)
      await queryClient.cancelQueries(key)

      const previousCertificate = queryClient.getQueryData<ICertificate>(key)

      if (previousCertificate) {
        queryClient.setQueryData(key, {
          ...previousCertificate,
          ...payload,
        })
      }

      return { previousCertificate }
    },
    onError(...args) {
      const [, payload, context] = args

      if (context?.previousCertificate) {
        queryClient.setQueryData(
          certificateKeys.one(payload.ZeugnisID),
          context.previousCertificate
        )
      }

      if (toastId) {
        toast.error(t("error"), { id: toastId })
      }

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      const [data] = args
      queryClient.setQueryData(certificateKeys.one(data.ZeugnisID), data)

      if (toastId) {
        toastMessage
          ? toast.success(toastMessage, { id: toastId })
          : toast.success(t("changesSaved"), { id: toastId })
      }

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
