import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query"

import { ELanguages, EUserRoles } from "src/constants"
import { IUser, OrganisationUnit } from "types"
import { fetchJson } from "utils/network"

import { userKeys } from "./keys"

export type TUseCreateUserPayload = Pick<
  Omit<IUser, "ProfileFileId">,
  "Aktiv" | "Email" | "Nachname" | "Vorname"
> & {
  CatSprache: { ID: ELanguages }
  SysRoles: { ID: EUserRoles }[]
  CatFunktionID: number
  CatGeschlechtID: 1 | 2
  ProfileFileId?: string
  OrganisationUnits: OrganisationUnit[]
}

type TData = IUser
type TError = unknown
type TVariables = {
  payload: TUseCreateUserPayload
  queryToUpdate: ReturnType<typeof userKeys.all>
}
type TContext = {
  previousUsers?: IUser[]
}
type THook = (
  options?: UseMutationOptions<TData, TError, TVariables, TContext>
) => UseMutationResult<TData, TError, TVariables, TContext>

const createUser = ({ payload }: TVariables) =>
  // @ts-ignore
  fetchJson<TData>("benutzer", { method: "POST", body: payload })

export const useCreateUser: THook = (options) => {
  const queryClient = useQueryClient()

  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(createUser, {
    onMutate(...args) {
      const [{ queryToUpdate }] = args

      toastId = toast.loading(t("inProgress"))

      const previousUsers = queryClient.getQueryData<IUser[]>(queryToUpdate)

      void options?.onMutate?.(...args)

      return { previousUsers }
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      const [data, { queryToUpdate }, ctx] = args

      if (ctx?.previousUsers) {
        queryClient.setQueryData(queryToUpdate, [data, ...ctx.previousUsers])
      }

      toast.success(t("done"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
