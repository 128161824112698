import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query"
import * as TsToolbelt from "ts-toolbelt"

import { fetchJson } from "utils/network"

import { certificateKeys } from "./keys"
import {
  ICertificate,
  TCustomTaskGroup,
  TCustomTaskGroupTask,
} from "./useCertificate"

type TData = TCustomTaskGroup[]
type TError = unknown
type TPayload = {
  certificateId: number
  payload: Array<
    Omit<
      TsToolbelt.Object.Required<
        TsToolbelt.Object.Optional<TCustomTaskGroup>,
        "Title"
      >,
      "Tasks"
    > & {
      Tasks?: Array<
        TsToolbelt.Object.Required<
          TsToolbelt.Object.Optional<TCustomTaskGroupTask>,
          "Title"
        >
      >
    }
  >
}
type TContext = { previousCertificate?: ICertificate }
type TOptions = UseMutationOptions<TData, TError, TPayload, TContext>
type THook = (
  options?: TOptions
) => UseMutationResult<TData, TError, TPayload, TContext>

export const useUpdateCustomTaskGroups: THook = (options) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(
    ({ certificateId, payload }) =>
      // @ts-ignore
      fetchJson<TData>(`Zeugnisse/${certificateId}/CustomTaskGroups`, {
        method: "PUT",
        body: payload,
      }),
    {
      onMutate({ certificateId, payload }) {
        const key = certificateKeys.one(certificateId)

        const previousCertificate = queryClient.getQueryData<ICertificate>(key)

        const payloadWithDefaultTaskId = payload.map(group => {
            return {
              ...group,
              Tasks: group.Tasks?.map(task => {
                if (task.CustomTaskID === undefined) {
                  return { ...task, CustomTaskID: task.Sequenz };
                }
                return task;
              })
            };
          });

        if (previousCertificate) {
          queryClient.setQueryData(key, {
            ...previousCertificate,
            CustomTaskGroups: payloadWithDefaultTaskId,
          })
        }

        return { previousCertificate }
      },
      onError(...args) {
        const [, { certificateId }, ctx] = args

        if (ctx?.previousCertificate) {
          queryClient.setQueryData(
            certificateKeys.one(certificateId),
            ctx.previousCertificate
          )
        }

        toast.error(t("error"), { id: toastId })

        void options?.onError?.(...args)
      },
      onSuccess(...args) {
        toast.success(t("changesSaved"), { id: toastId })

        void options?.onSuccess?.(...args)
      },
      ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
    }
  )
}
