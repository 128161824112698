import * as React from "react"

function SvgSmileySmirk(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      width="100%"
      height="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="100%" height="100%" rx={12} fill="currentColor" />
      <g stroke="#000" strokeLinecap="round" strokeLinejoin="round">
        <path d="M18 14.5A6.5 6.5 0 019.5 18M.5 12a11.5 11.5 0 1023 0 11.5 11.5 0 00-23 0v0z" />
        <path d="M8.5 7.75a.25.25 0 110 .5.25.25 0 010-.5zM15.5 7.75a.25.25 0 100 .5.25.25 0 000-.5z" />
      </g>
    </svg>
  )
}

export default SvgSmileySmirk
