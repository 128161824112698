import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { ELanguages } from "src/constants"
import { fetchJson } from "utils/network"

import { useSystemInfo } from "./useSystemInfo"

export type TLanguageNames = "English" | "Deutsch" | "Frances" | "Italiano"

export interface ICertificateLanguage {
  CatSpracheID: ELanguages
  Bezeichnung: TLanguageNames
}

type TData = ICertificateLanguage[]
type TOptions = UseQueryOptions<TData> & {
  returnAll?: boolean
}
type THook = (options?: TOptions) => UseQueryResult<TData>

// @ts-ignore
const fetchTodos = () => fetchJson<TData>("catsprachen")

export const useCertificateLanguages: THook = (options) => {
  const systemInfo = useSystemInfo()

  // @ts-ignore
  return useQuery("CERTIFICATE_LANGUAGES", fetchTodos, {
    ...options,
    enabled: Boolean(systemInfo.data),
    // NOTE:
    // The ugly logic below is due to a sloppy server logic implementation.
    // The `enabled` property should be ideally attached to the language object itself.
    select(data) {
      if (options?.returnAll) {
        return data
      }
      if (systemInfo.data) {
        return data.filter(({ CatSpracheID }) => {
          if (
            CatSpracheID === ELanguages.DE &&
            !systemInfo.data.EnableLanguageGe
          ) {
            return false
          }
          if (
            CatSpracheID === ELanguages.EN &&
            !systemInfo.data.EnableLanguageEn
          ) {
            return false
          }
          if (
            CatSpracheID === ELanguages.FR &&
            !systemInfo.data.EnableLanguageFr
          ) {
            return false
          }
          if (
            CatSpracheID === ELanguages.IT &&
            !systemInfo.data.EnableLanguageIt
          ) {
            return false
          }
          return true
        })
      }
      return data
    },
  })
}
