import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { IFunction, DEFAULT_REQUEST_FUNCTION_PARAMS } from "hooks/data"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

type TQueryParams = operations["CatFunktionen_Post"]["parameters"]["query"]

type TData = IFunction
type TError = unknown
type TPayload = Pick<IFunction, "IsDeactivated" | "InitSettings"> & {
  BezeichnungMaennlichML: Partial<IFunction["BezeichnungMaennlichML"]>
  BezeichnungWeiblichML: Partial<IFunction["BezeichnungWeiblichML"]>
}
type THook = (
  params: Partial<TQueryParams>,
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

const createFunction = (payload: TPayload, params: Partial<TQueryParams>) =>
  fetchJson<TData, false, TQueryParams>("catfunktionen", {
    queryParams: {
      ...DEFAULT_REQUEST_FUNCTION_PARAMS,
      ...params,
    },
    method: "POST",
    body: payload,
  })

export const useCreateFunction: THook = (params, options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation((payload) => createFunction(payload, params), {
    onMutate(...args) {
      toastId = toast.loading(t("inProgress"))

      options?.onMutate?.(...args)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("done"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
