import { AxiosError } from "axios"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"
import { encode } from "utf8"

import { IUser } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

type TQueryParams = operations["Benutzer_GetCurrent"]["parameters"]["query"]

type TData = IUser
type TError = AxiosError
type TPayload = {
  email?: string
  password?: string
  token?: string
}
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

export const useLoginUser: THook = (options) =>
  useMutation(
    ({ email, password, token }) =>
      fetchJson<TData, false, TQueryParams>(`benutzer/current`, {
        headers: {
          Authorization: token
            ? `Bearer ${token}`
            : email && password
            ? `Basic ${btoa(encode(`${email}:${password}`))}`
            : "",
        },
        queryParams: {
          includeAuthorizationInfo: false,
        },
      }),
    options
  )
