import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { companyKeys } from "."
import { TAltCompany } from "./useAltCompanies"

type TData = TAltCompany
type TQueryParams =
  operations["CompanyAlternativeNames_GetById"]["parameters"]["query"]
type TArgs = {
  id?: operations["CompanyAlternativeNames_GetById"]["parameters"]["path"]["id"]
  queryParams?: TQueryParams
  options?: UseQueryOptions<TData>
}
type THook = (args: TArgs) => UseQueryResult<TData>

export const useAltCompany: THook = ({ id, options, queryParams }) =>
  useQuery(
    companyKeys.oneAlt(id),
    () =>
      id
        ? fetchJson<TData, false, TQueryParams>(
            // @ts-ignore
            `CompanyAlternativeNames/${id}`,
            {
              queryParams: {
                renderSpracheId: "de",
                ...queryParams,
              },
            }
          )
        : Promise.reject(new Error('"id" are required')),
    // @ts-ignore
    {
      enabled: Boolean(id),
      ...options,
    }
  )
