import { AxiosResponse } from "axios"
import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from "react-query"

import { TODataParams } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { employeeKeys } from "."

export type TEmployeeForTable = {
  EmployeeName: string
  DegreeOfEmployment: number
  DepartmentID: number
  DepartmentName: string
  EmploymentTypeID: number
  EmploymentTypeName: string
  ExitDate: string
  JobTitleID: number
  JobTitleName: string
  ID: number
  NumberOfSubordinates: string
  PersonalNumber: string
  DateOfBirth: string
}

export type TEmployeesForTableQueryParams = TODataParams &
  operations["Mitarbeiter_GetEmployees"]["parameters"]["query"]

type TData = TEmployeeForTable[]
type TResponse = AxiosResponse<TData>
type TGetEmployeeesForTableParams = {
  pageParam?: {
    recordsToSkip: number
  }
  queryKey: [string, TEmployeesForTableQueryParams]
}
type THook = (
  params?: TEmployeesForTableQueryParams,
  options?: UseInfiniteQueryOptions<TResponse>
) => UseInfiniteQueryResult<TResponse>

function getEmployeesForTable({
  pageParam = {
    recordsToSkip: 0,
  },
  queryKey,
}: TGetEmployeeesForTableParams) {
  const [, queryParams] = queryKey
  const { recordsToSkip } = pageParam

  return fetchJson<TData, true, TEmployeesForTableQueryParams>(
    "mitarbeiter/getemployees",
    {
      queryParams: {
        ...queryParams,
        $skip: recordsToSkip,
        $inlinecount: "allpages",
      },
      fullResponse: true,
    }
  )
}

export const useEmployeesForTableInfinite: THook = (params, options) =>
  useInfiniteQuery(
    employeeKeys.allForTableInfinite(params),
    // @ts-ignore
    getEmployeesForTable,
    {
      getNextPageParam: (lastPage, allPages) => {
        const proxiedUrl = new URL(
          lastPage.config.url ?? "",
          window.location.href
        )
        const url = proxiedUrl.searchParams.get("originalUrl")
          ? new URL(
              proxiedUrl.searchParams.get("originalUrl") ?? "",
              window.location.href
            )
          : proxiedUrl
        const hasRequestedTotalCount = url.searchParams.has("$inlinecount")

        if (!hasRequestedTotalCount) {
          console.error(
            "useCertificatesForTableInfinite: The total count was not requested in the query. This is required for infinite queries."
          )
          return undefined
        }

        const recordsToSkip = allPages.reduce((accumulator, page) => {
          accumulator += page.data.length
          return accumulator
        }, 0)
        if (recordsToSkip >= Number(lastPage.headers.totalcount))
          return undefined

        return { recordsToSkip }
      },
      ...options,
    }
  )
