import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import de from "./de.json"
import en from "./en.json"
import fr from "./fr.json"
import it from "./it.json"

export const resources = { en, de, fr, it } as const

void i18n.use(initReactI18next).init({
  resources,
  lng: "de",
  fallbackLng: "en",
  // debug: true,
})

export default i18n
