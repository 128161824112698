/* eslint-disable import/no-named-as-default-member */
import React from 'react';

export function useDisclose(initState?: boolean) {
  const [isOpen, setIsOpen] = React.useState(initState || false);
  const onOpen = React.useCallback(() => {
    setIsOpen(true);
  }, []);
  const onClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);;
  const onToggle = React.useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);;
  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  };
}
