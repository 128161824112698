import * as React from "react"

function SvgChevronUpDown(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M119.583 52.494L70 2.911 20.417 52.494M119.583 87.494L70 137.077 20.417 87.494"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgChevronUpDown
