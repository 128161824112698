import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { fetchJson } from "utils/network"

import { ICertificateType } from "./useCertificateTypes"

type TData = ICertificateType
type THook = (
  id: number,
  options?: UseQueryOptions<TData>
) => UseQueryResult<TData>

export const useCertificateType: THook = (id, options) =>
  useQuery(
    ["CERTIFICATE_TYPE", id],
    // @ts-ignore
    () => fetchJson<TData>(`CatZeugnisTypen/${id}`),
    // @ts-ignore
    options
  )
