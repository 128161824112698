import { useQuery, UseQueryResult } from "react-query"

import { fetchJson } from "utils/network"

import { fontKeys } from "."

export interface IFont {
  SchriftID: number
  Name: string
  ModDate: string
}

type TData = IFont[]
type THook = () => UseQueryResult<TData>

export const useFonts: THook = () =>
  // @ts-ignore
  useQuery(fontKeys.all, () => fetchJson<TData>(`schriftarten`))
