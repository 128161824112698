import { AxiosResponse } from "axios"
import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from "react-query"

import { TODataParams } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { certificateKeys } from "./keys"

export type TCertificateRequestForTable = {
  CertificateRequestID: number
  EmployeeNumber: string
  FullName: string
  DateOfBirth: string
  LineManager?: string
  CertificateTypeID?: number
  LanguageID?: string
  Comment?: string
  CreaDate?: string
}

export type TCertificatesRequestsForTableQueryParams = TODataParams &
  operations["Zeugnisse_GetCertificateRequests"]["parameters"]["query"]

type TData = TCertificateRequestForTable[]
type TResponse = AxiosResponse<TData>
type THook = (
  params?: TCertificatesRequestsForTableQueryParams,
  options?: UseInfiniteQueryOptions<TResponse>
) => UseInfiniteQueryResult<TResponse>
type TGetCertificatesRequestsForTableParams = {
  pageParam?: {
    recordsToSkip: number
  }
  queryKey: [string, TCertificatesRequestsForTableQueryParams]
}

function getCertificatesRequestsForTable({
  pageParam = {
    recordsToSkip: 0,
  },
  queryKey,
}: TGetCertificatesRequestsForTableParams) {
  const [, queryParams] = queryKey
  const { recordsToSkip } = pageParam

  return fetchJson<TData, true, TCertificatesRequestsForTableQueryParams>(
    "zeugnisse/getcertificaterequests",
    {
      fullResponse: true,
      queryParams: {
        ...queryParams,
        $skip: recordsToSkip,
        $inlinecount: "allpages",
      },
    }
  )
}

export const useCertificatesRequestsForTableInfinite: THook = (
  params,
  options
) =>
  useInfiniteQuery(
    certificateKeys.allRequestsForTableInfinite(params),
    // @ts-ignore
    getCertificatesRequestsForTable,
    {
      getNextPageParam: (lastPage, allPages) => {
        const proxiedUrl = new URL(
          lastPage.config.url ?? "",
          window.location.href
        )
        const url = proxiedUrl.searchParams.get("originalUrl")
          ? new URL(
              proxiedUrl.searchParams.get("originalUrl") ?? "",
              window.location.href
            )
          : proxiedUrl
        const hasRequestedTotalCount = url.searchParams.has("$inlinecount")

        if (!hasRequestedTotalCount) {
          console.error(
            "useCertificatesRequestsForTableInfinite: The total count was not requested in the query. This is required for infinite queries."
          )
          return undefined
        }

        const recordsToSkip = allPages.reduce((accumulator, page) => {
          accumulator += page.data.length
          return accumulator
        }, 0)

        if (recordsToSkip >= Number(lastPage.headers.totalcount))
          return undefined

        return { recordsToSkip }
      },
      ...options,
    }
  )
