import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query"

import { fetchJson } from "utils/network"

import { fontKeys } from "."
import { IFont } from "./useFonts"

type TData = IFont[]
type TError = unknown
type TPayload = number
type TContext = {
  previousFonts?: IFont[]
}
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload, TContext>
) => UseMutationResult<TData, TError, TPayload, TContext>

const deleteFont = (id: TPayload) =>
  // @ts-ignore
  fetchJson<TData>(`Schriftarten/${id}`, { method: "DELETE" })

export const useDeleteFont: THook = (options) => {
  const queryClient = useQueryClient()
  const key = fontKeys.all

  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(deleteFont, {
    async onMutate(...args) {
      const [id] = args

      toastId = toast.loading(t("inProgress"))

      await queryClient.cancelQueries(key)

      const previousFonts = queryClient.getQueryData<IFont[]>(key)

      if (previousFonts) {
        queryClient.setQueryData(
          key,
          previousFonts.filter((font) => font.SchriftID !== id)
        )
      }

      void options?.onMutate?.(...args)

      return { previousFonts }
    },
    onError(...args) {
      const [, , context] = args

      if (context?.previousFonts) {
        queryClient.setQueryData(key, context.previousFonts)
      }

      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("done"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
