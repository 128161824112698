import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { TTask } from "."

type TQueryParams = operations["Tasks_Put"]["parameters"]["query"]

type TData = TTask
type TError = unknown
type TPayload = TTask
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

const updateTask = (task: TPayload) =>
  // @ts-ignore
  fetchJson<TTask, false, TQueryParams>(`Tasks/${task.CatAufgabeTemplateID}`, {
    method: "PUT",
    queryParams: {
      renderSpracheId: "de",
    },
    body: task,
  })

export const useUpdateTask: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(updateTask, {
    onMutate(...args) {
      toastId = toast.loading(t("updating"))

      options?.onMutate?.(...args)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("changesSaved"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
