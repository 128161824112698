import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { employeeKeys, IEmployee } from "."

type THook = (
  params?: IEmployeesQueryParams,
  options?: UseQueryOptions<IEmployee[]>
) => UseQueryResult<IEmployee[]>

export type IEmployeesQueryParams =
  operations["Mitarbeiter_GetList"]["parameters"]["query"]

const getEmployees = (params?: IEmployeesQueryParams) =>
  fetchJson<IEmployee[]>("mitarbeiter", {
    queryParams: { ...params },
  })

export const useEmployees: THook = (params, options) =>
  // @ts-ignore
  useQuery(employeeKeys.all(params), () => getEmployees(params), options)
