import FirstLoginToast from "../_shared/FirstLoginToast"

type Props = {
  children: React.ReactNode
}

const Wrapper: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div>{children}</div>
      <FirstLoginToast />
    </>
  )
}

export default Wrapper
