import * as React from "react"

function SvgChevronDown(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.156 32.824l64.896 73.343a2.917 2.917 0 004.369 0l64.901-73.343"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={5.83333}
      />
    </svg>
  )
}

export default SvgChevronDown
