import * as React from "react"

const SvgTable = (props) => (
  <svg
    viewBox="0 0 24 24"
    height="100%"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.5 5.5a1 1 0 0 1-1 1h-21a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h21a1 1 0 0 1 1 1ZM23.5 13.5a1 1 0 0 1-1 1h-21a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h21a1 1 0 0 1 1 1ZM23.5 21.5a1 1 0 0 1-1 1h-21a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h21a1 1 0 0 1 1 1Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgTable
