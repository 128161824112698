import { useLayoutEffect } from "react"

export default function useBlurMainSection(): void {
  useLayoutEffect(() => {
    const $navigationSidebar = document.querySelector(".NavigationSidebar")
    const $mainSection = document.querySelector("main")

    if ($navigationSidebar && $mainSection) {
      $navigationSidebar.classList.add("blur-[1.5px]")
      $mainSection.classList.add("blur-[1.5px]")
    }

    return () => {
      if ($navigationSidebar && $mainSection) {
        $navigationSidebar.classList.remove("blur-[1.5px]")
        $mainSection.classList.remove("blur-[1.5px]")
      }
    }
  }, [])
}
