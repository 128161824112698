import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query"

import { fetchBlob } from "utils/network"

import { wordTemplateKeys } from "./keys"
import { TWordTemplate } from "./useWordTemplates"

type TData = TWordTemplate
type TError = unknown
type TPayload = FormData
type TOptions = UseMutationOptions<TData, TError, TPayload>
type TReturn = UseMutationResult<TData, TError, TPayload>

const uploadWordTemplate = (payload: TPayload) =>
  fetchBlob<TWordTemplate>("zeugnistemplate/new/filedata", {
    method: "POST",
    body: payload,
  })

export function useUploadNewWordTemplate(options?: TOptions): TReturn {
  const queryClient = useQueryClient()
  const key = wordTemplateKeys.all

  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(uploadWordTemplate, {
    onMutate(...args) {
      toastId = toast.loading(t("inProgress"))

      options?.onMutate?.(...args)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      const [data] = args

      const templates = queryClient.getQueryData<TWordTemplate[]>(key)

      if (templates) {
        queryClient.setQueryData(key, [...templates, data])
      }

      toast.success(t("done"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
