import { useMutation, UseMutationOptions } from "react-query"

import { fetchJson } from "utils/network"

type TImportEmployees = {
  jobRunId: string
}

export function useImportEmployees(
  options?: UseMutationOptions<TImportEmployees, Error, TImportEmployees>
) {
  return useMutation<TImportEmployees, Error, TImportEmployees>(
    async (payload) => {
      const response = await fetchJson<TImportEmployees>("api/ink-integrations/legacy-import", {
        method: "POST",
        body: payload,
        useLocalAir:true
      })
      return response
    },
    {
      ...options,
    }
  )
}
