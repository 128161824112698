import * as React from "react"

function SvgCalendar(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      {...props}
    >
      <g transform="scale(5.83333)">
        <defs>
          <style>
            {
              ".calendar_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round}"
            }
          </style>
        </defs>
        <rect
          className="calendar_svg__a"
          x={0.5}
          y={2.501}
          width={23}
          height={21}
          rx={1}
          ry={1}
        />
        <path
          className="calendar_svg__a"
          d="M5.5.501v5M18.5.501v5M.5 7.501h23"
        />
      </g>
    </svg>
  )
}

export default SvgCalendar
