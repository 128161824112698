import * as React from "react"

function SvgCertificates(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M123.707 26.25a5.833 5.833 0 011.71 4.124V131.25a5.833 5.833 0 01-5.834 5.833H20.417a5.833 5.833 0 01-5.834-5.833V8.75a5.833 5.833 0 015.834-5.833h77.542a5.833 5.833 0 014.124 1.709zM35 93.333h70M35 110.833h70M35 75.833h70M35 58.333h70M35 40.833h40.833M35 40.833h40.833M35 58.333h70M35 75.833h70M35 93.333h70M35 110.833h70"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgCertificates
