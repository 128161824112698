import { AxiosResponse } from "axios"
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { TLanguageCodes, TODataParams } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

export interface IDepartment {
  CatAbteilungID: number
  Bezeichnung: string
  BezeichnungML: Record<TLanguageCodes, string> & {
    Code: string
  }
  IsDeactivated: boolean
}

type TData = IDepartment[]
type TQueryParams =
  operations["CatAbteilungen_GetList"]["parameters"]["query"] & TODataParams

const getDepartments = <TFullResponse>(params?: TQueryParams) =>
  fetchJson<TData, TFullResponse, TQueryParams>("catabteilungen", {
    queryParams: { orderByLanguageId: "de", orderByDesc: false, ...params },
    fullResponse: params?.$inlinecount === "allpages",
  })

export const useDepartments = <TFullResponse extends boolean = false>(
  params?: TQueryParams,
  options?: UseQueryOptions<
    TFullResponse extends true ? AxiosResponse<TData> : TData
  >
): UseQueryResult<TFullResponse extends true ? AxiosResponse<TData> : TData> =>
  useQuery<TFullResponse extends true ? AxiosResponse<TData> : TData>(
    ["DEPARTMENTS", params],
    () => getDepartments<TFullResponse>(params),
    options
  )
