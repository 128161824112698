import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { fetchJson } from "utils/network"

import { TClosingStatement } from "./useClosingStatement"

type TData = TClosingStatement
type TError = unknown
type TPayload = Pick<
  TClosingStatement,
  "CatSchlussformelBlockID" | "CatZeugnisTypeID"
> & {
  TemplateML: Partial<
    Pick<TClosingStatement["TemplateML"], "de" | "en" | "fr" | "it">
  >
}
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

function createClosingStatement(payload: TPayload) {
  return fetchJson<TData>(`catschlussformeloptionen`, {
    method: "POST",
    body: payload,
  })
}

export const useCreateClosingStatement: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(createClosingStatement, {
    onMutate(variables) {
      toastId = toast.loading(t("inProgress"))

      options?.onMutate?.(variables)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("done"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
