import * as React from "react"

function SvgLogout(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M8.248 96.25a67.083 67.083 0 100-52.5M72.917 93.333L96.25 70 72.917 46.667M96.25 70H2.917"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgLogout
