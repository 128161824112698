import { useQuery, UseQueryResult, UseQueryOptions } from "react-query"

import { EUserRoles } from "src/constants"
import { TLanguageCodes } from "types"
import { fetchJson } from "utils/network"

import { userKeys } from "./keys"

export const enum EUserRoleCodes {
  USER = "Benutzer",
  HR = "MitarbeiterHR",
  SUPER_HR = "FachlicherAdministrator",
  TECH_ADMIN = "TechnischerAdministrator",
}

type TUserRole = {
  SysRoleID: EUserRoles
  Code: EUserRoleCodes
  Bezeichnung: string
  BezeichnungML: Record<TLanguageCodes, string> & {
    CatMLTextID: number
    Code: string
  }
}

type TData = TUserRole[]
type TError = unknown
type THook = (
  options?: UseQueryOptions<TData, TError>
) => UseQueryResult<TData, TError>

export const useUserRoles: THook = (options) =>
  // @ts-ignore
  useQuery(userKeys.roles, () => fetchJson<TData>("sysroles"), options)
