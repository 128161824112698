import { TUseFunctionsQueryParams, TUseFunctionQueryParams } from "."

export const functionKeys = {
  all: (params: TUseFunctionsQueryParams) => ["FUNCTIONS", params] as const,
  allInfinite: (params: TUseFunctionsQueryParams) =>
    ["FUNCTIONS_INFINITE", params] as const,
  initSettings: () => ["FUNCTIONS", "INIT_SETTINGS"] as const,
  one: (id: number, params: TUseFunctionQueryParams) =>
    [...functionKeys.all(params), id] as const,
}
