import * as React from "react"

function User(props) {
  return (
    <svg
      className={props.className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99999 5.40005C6.9941 5.40005 7.79999 4.59416 7.79999 3.60005C7.79999 2.60594 6.9941 1.80005 5.99999 1.80005C5.00587 1.80005 4.19999 2.60594 4.19999 3.60005C4.19999 4.59416 5.00587 5.40005 5.99999 5.40005Z"
        fill="black"
      />
      <path
        d="M1.79999 10.8C1.79999 8.48045 3.68039 6.60005 5.99999 6.60005C8.31958 6.60005 10.2 8.48045 10.2 10.8H1.79999Z"
        fill="black"
      />
    </svg>
  )
}

export default User
