import { AxiosResponse } from "axios"
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { TLanguageCodes, TODataParams } from "types"
import { fetchJson } from "utils/network"

type TData = Record<string, Record<TLanguageCodes, string>>

export const useTextVariables = <TFullResponse>(
  params?: TODataParams,
  options?: UseQueryOptions<
    TFullResponse extends true ? AxiosResponse<TData> : TData
  >
): UseQueryResult<TFullResponse extends true ? AxiosResponse<TData> : TData> =>
  useQuery(
    "TEXT_VARIABLES",
    () =>
      fetchJson<TData, TFullResponse, TODataParams>("variables", {
        queryParams: params,
        fullResponse: params?.$inlinecount === "allpages",
      }),
    // @ts-ignore
    options
  )
