import { Menu, Transition } from "@headlessui/react"
import classNames from "classnames"
import { ComponentProps, Fragment } from "react"
import type { SyntheticEvent, ReactNode, ComponentType } from "react"

import FloatPlacement from "./FloatPlacement"

export type TMenuItem = {
  text: string
  onClick: (event: SyntheticEvent) => void
  isActive?: boolean
  Icons?: {
    Active: ComponentType<ComponentProps<"svg">>
    Inactive: ComponentType<ComponentProps<"svg">>
  }
  disabled?: boolean
  isSelected?: boolean
}

type TProps = {
  className?: string
  button: {
    content: ReactNode
    className?: string
    props?: Omit<JSX.IntrinsicElements["button"], "ref">
  }
  menuItems: TMenuItem[]
}

const Dropdown = ({ button, menuItems, className }: TProps): JSX.Element => {
  return (
    <div className={classNames(["Dropdown", className, "flex items-center"])}>
      <Menu as="div" className={classNames(["relative inline-flex h-full"])}>
        {({ open }) => {
          return (
            <>
              <span className={classNames(["sr-only", open && "opened"])}>
                Open options
              </span>
              <FloatPlacement>
                <Menu.Button className={button.className} {...button.props}>
                  {button.content}
                </Menu.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className={classNames([
                      "absolute right-0 top-[100%] z-10 py-1",
                      "px-[1px]",
                      "min-w-max",
                      "rounded-lg",
                      "origin-top-right bg-white",
                      "shadow-lg ring-1 ring-black",
                      "focus:outline-none",
                    ])}
                  >
                    <div className="">
                      {menuItems.map((item, idx) => {
                        const { text, Icons, onClick, disabled, isSelected } =
                          item
                        return (
                          <Menu.Item key={`${text}__${idx}`} {...{ disabled }}>
                            {({ active }) => (
                              <button
                                type="button"
                                className={classNames([
                                  "group flex w-full items-center px-4 py-1.5 text-sm text-black",
                                  active && "bg-clearSky",
                                  isSelected && "bg-clearSky",
                                  disabled && "buttonDisabled text-opacity-50",
                                ])}
                                {...{ onClick }}
                              >
                                {Icons &&
                                  (active ? (
                                    <Icons.Active
                                      className="icon mr-3"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <Icons.Inactive
                                      className="icon mr-3"
                                      aria-hidden="true"
                                    />
                                  ))}
                                {text}
                              </button>
                            )}
                          </Menu.Item>
                        )
                      })}
                    </div>
                  </Menu.Items>
                </Transition>
              </FloatPlacement>
            </>
          )
        }}
      </Menu>
    </div>
  )
}

export default Dropdown
