import * as _ from "lodash-es"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { fetchJson } from "utils/network"

type TData = void
type TError = unknown
type TPayload = {
  email: string
  company: string
  firstName: string
  lastName: string
  message: string
  phone?: string
}
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

export const useSendSupportRequest: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(
    (payload) =>
      fetchJson<TData>(`kontakt`, {
        method: "POST",
        body: {
          Email: payload.email,
          Firma: payload.company,
          Vorname: payload.firstName,
          Nachname: payload.lastName,
          Nachricht: payload.message,
          Tel: payload.phone,
        },
      }),
    {
      onMutate(payload) {
        toastId = toast.loading(t("inProgress"))

        options?.onMutate?.(payload)
      },
      onError(...args) {
        toast.error(t("error"), { id: toastId })

        void options?.onError?.(...args)
      },
      onSuccess(...args) {
        toast.success(t("done"), { id: toastId })

        void options?.onSuccess?.(...args)
      },
      ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
    }
  )
}
