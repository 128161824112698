import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query"

import { fetchJson } from "utils/network"

import { companyKeys } from "./keys"
import { TCompany } from "./useCompanies"

type TData = TCompany
type TError = unknown
type TPayload = TCompany
type TContext = {
  previousCompanies?: TCompany[]
}
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload, TContext>
) => UseMutationResult<TData, TError, TPayload, TContext>

const updateCompany = (payload: TPayload) =>
  // @ts-ignore
  fetchJson(`CatFirmen/${payload.CatFirmaID}`, { method: "PUT", body: payload })

export const useCompanyMutation: THook = (options) => {
  const queryClient = useQueryClient()
  const key = companyKeys.all

  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(updateCompany, {
    async onMutate(payload) {
      toastId = toast.loading(t("updating"))

      await queryClient.cancelQueries(key)

      const previousCompanies = queryClient.getQueryData<TCompany[]>(key)

      if (previousCompanies) {
        queryClient.setQueryData(
          key,
          previousCompanies.map((company) => {
            if (company.CatFirmaID === payload.CatFirmaID) {
              return payload
            }
            return company
          })
        )
      }

      return { previousCompanies }
    },
    onError(...args) {
      const [, , context] = args

      toast.error(t("error"), { id: toastId })

      if (context?.previousCompanies) {
        queryClient.setQueryData(key, context.previousCompanies)
      }

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      const [data, , context] = args

      if (context?.previousCompanies) {
        queryClient.setQueryData(
          key,
          context.previousCompanies.map((company) => {
            if (company.CatFirmaID === data.CatFirmaID) {
              return data
            }
            return company
          })
        )
      }

      toast.success(t("changesSaved"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
