import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { TLanguageCodes } from "types"
import { fetchJson } from "utils/network"

import { companyKeys } from "."

export type TCompany = {
  AllowRequestCertificate: boolean
  CatFirmaID: number
  Email: string | null
  Name: string
  NameML: Record<TLanguageCodes, string>
  EnhancedCertificateRequestInstructionML?: Record<TLanguageCodes, string>
  SubtitleML?: Record<TLanguageCodes, string>
  ProfileFileId: string | null
}

type TData = TCompany[]
type THook = (options?: UseQueryOptions<TData>) => UseQueryResult<TData>

export const useCompanies: THook = (options) =>
  // @ts-ignore
  useQuery(companyKeys.all, () => fetchJson<TData>("catfirmen"), options)
