import React, { FC } from "react"

import { cn } from "utils"

interface Props {
  className?: string
}

const Spinner: FC<Props> = ({ className = "" }) => (
  <div
    data-testid="loading"
    className={cn(
      "h-8 w-8 animate-spin rounded-full",
      "border-2 border-t-2 border-gray-200 border-r-slate-700 border-t-slate-700",
      className
    )}
  />
)

export default Spinner
