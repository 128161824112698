import * as React from "react"

function SvgHorizontalDots(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        // fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M2.917 70a14.583 14.583 0 1029.166 0 14.583 14.583 0 10-29.166 0zM55.417 70a14.583 14.583 0 1029.166 0 14.583 14.583 0 10-29.166 0zM107.917 70a14.583 14.583 0 1029.166 0 14.583 14.583 0 10-29.166 0z"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgHorizontalDots
