import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

type TData = void
type TError = unknown
type TPayload =
  operations["CompanyAlternativeNames_Delete"]["parameters"]["path"]["id"]
type TArgs = {
  options?: UseMutationOptions<TData, TError, TPayload>
}
type THook = (args?: TArgs) => UseMutationResult<TData, TError, TPayload>

export const useDeleteAltCompany: THook = ({ options } = {}) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(
    (id: TPayload) =>
      // @ts-ignore
      fetchJson<TData>(`CompanyAlternativeNames/${id}`, { method: "DELETE" }),
    {
      onMutate(...args) {
        toastId = toast.loading(t("inProgress"))

        options?.onMutate?.(...args)
      },
      onError(...args) {
        toast.error(t("error"), { id: toastId })

        void options?.onError?.(...args)
      },
      onSuccess(...args) {
        toast.success(t("done"), { id: toastId })

        void options?.onSuccess?.(...args)
      },
      ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
    }
  )
}
