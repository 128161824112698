export function GoogleIcon(props) {
  return (
    <svg
      className="icon"
      fill="currentColor"
      aria-hidden="true"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="google icon">
        <path
          id="Vector"
          d="M19.9894 10.1873C19.9894 9.36789 19.9214 8.76995 19.7741 8.14987H10.1992V11.8482H15.8195C15.7062 12.7673 15.0943 14.1514 13.7345 15.0815L13.7155 15.2053L16.7429 17.4971L16.9526 17.5176C18.8789 15.7791 19.9894 13.2212 19.9894 10.1873"
          fill="#9CA3AF"
        />
        <path
          id="Vector_2"
          d="M10.1993 19.9321C12.9527 19.9321 15.2643 19.0462 16.9527 17.5182L13.7346 15.0821C12.8734 15.669 11.7176 16.0787 10.1993 16.0787C7.50241 16.0787 5.2135 14.3403 4.39757 11.9374L4.27797 11.9473L1.13 14.328L1.08884 14.4399C2.76586 17.6953 6.21059 19.9321 10.1993 19.9321Z"
          fill="#D1D5DB"
        />
        <path
          id="Vector_3"
          d="M4.39753 11.9369C4.18224 11.3168 4.05764 10.6524 4.05764 9.9659C4.05764 9.27934 4.18224 8.61499 4.3862 7.99491L4.3805 7.86285L1.19309 5.44392L1.08881 5.49239C0.39763 6.84331 0.00102997 8.36033 0.00102997 9.9659C0.00102997 11.5715 0.39763 13.0884 1.08881 14.4393L4.39753 11.9369"
          fill="#6B7280"
        />
        <path
          id="Vector_4"
          d="M10.1992 3.85338C12.1142 3.85338 13.4059 4.6617 14.1425 5.3372L17.0206 2.59109C15.253 0.985519 12.9527 2.28882e-05 10.1992 2.28882e-05C6.21057 2.28882e-05 2.76585 2.23674 1.08884 5.49216L4.38623 7.99468C5.21349 5.59186 7.50239 3.85338 10.1992 3.85338"
          fill="#8D929E"
        />
      </g>
    </svg>
  )
}
