import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"
import { RequireExactlyOne, PartialDeep } from "type-fest"

import { IEvaluationTemplate } from "pages/text-components/evaluations"
import { fetchJson } from "utils/network"

type TData = IEvaluationTemplate[]
type TError = unknown
type TVariables = {
  payload: RequireExactlyOne<
    PartialDeep<IEvaluationTemplate>,
    "CatSatzTemplateID"
  >[]
  showToast?: boolean
}
type THook = (
  options?: UseMutationOptions<TData, TError, TVariables>
) => UseMutationResult<TData, TError, TVariables>

async function updateTemplates({ payload }: TVariables) {
  const result: TData = []
  // IMPORTANT:
  // Update templates sequentially as server will trip up
  // if we attempt to do it in parallel.
  // eslint-disable-next-line no-restricted-syntax
  for (const template of payload) {
    const data = await fetchJson<IEvaluationTemplate>(
      // @ts-ignore
      `CatSatzTemplate/${template.CatSatzTemplateID}`,
      { method: "PUT", body: template }
    )
    result.push(data)
  }

  return result
}

export const useEvaluationTemplatesMutation: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  // @ts-ignore
  return useMutation(updateTemplates, {
    onMutate(variables) {
      const { showToast = true } = variables

      if (showToast) {
        toastId = toast.loading(t("updating"))
      }

      options?.onMutate?.(variables)
    },
    onError(...args) {
      const [, { showToast = true }] = args
      if (showToast) {
        toast.error(t("error"), { id: toastId })
      }

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      const [, { showToast = true }] = args

      if (showToast) {
        toast.success(t("changesSaved"), { id: toastId })
      }

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onError", "onSuccess"]),
  })
}
