import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { IUser } from "types"
import { fetchJson } from "utils/network"

import { userKeys } from "./keys"

type TData = IUser
type THook = (
  id?: number,
  options?: UseQueryOptions<TData>
) => UseQueryResult<TData>

const getUser = (id?: number) =>
  id
    ? // @ts-ignore
    fetchJson<IUser>(`Benutzer/${id}`)
    : Promise.reject(new Error("Missing id"))


export const useUser: THook = (id, options) =>
  // @ts-ignore
  useQuery(userKeys.one(id), () => getUser(id), {
    ...options, enabled: Boolean(id)
  })
