import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { fetchJson } from "utils/network"

export type TCustomIntroductionConfiguration = {
  UseCustomIntroduction?: boolean
  WorkCertificate?: string
  InterimWorkCertificate?: string
  EmploymentСonfirmation?: string
  InterimEmploymentСonfirmation?: string
  CertificateOfApprenticeship?: string
  InterimCertificateOfApprenticeship?: string
}

type TData = TCustomIntroductionConfiguration
type THook = (options?: UseQueryOptions<TData>) => UseQueryResult<TData>

export const useCustomIntroductionConfiguration: THook = (options) =>
  useQuery(
    "CUSTOM_INTRO_CONFIG",
    // @ts-ignore
    () => fetchJson<TData>("system/GetCustomIntroductionConfig"),
    // @ts-ignore
    options
  )
