import * as React from "react"

function SvgStandalone(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 603 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M455.542 370.189C455.542 501.281 445.16 560 314.069 560S6.498 444 .5 313c0-79.5 62.002-180 193.709-189.211 131.091 0 261.333 115.309 261.333 246.4z"
        fill="url(#standalone_svg__paint0_linear)"
      />
      <path
        d="M289.194 351.359c95.271 55.496 206.021 42.944 247.369-28.037 41.347-70.981 4.02-149.34-91.25-204.836-95.27-55.497-248.782-40.585-290.129 30.396-41.348 70.981 38.74 146.98 134.01 202.477z"
        fill="#FFB7BB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M455.367 380.799c-49.348 12.212-110.412 3.041-166.173-29.44-95.27-55.497-175.358-131.496-134.01-202.477 5.26-9.031 12.336-17.154 20.876-24.339a216.75 216.75 0 0118.149-.754c131.091 0 261.333 115.309 261.333 246.4 0 3.561-.058 7.098-.175 10.61z"
        fill="#FF5E3B"
      />
      <defs>
        <linearGradient
          id="standalone_svg__paint0_linear"
          x1={224.27}
          y1={123.789}
          x2={224.27}
          y2={560}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#000AFF" />
          <stop offset={1} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgStandalone
