import { ReactNode } from "react"

import { useCurrentUserContext } from "context/CurrentUserContext"
import { EUserRoles } from "src/constants"

type TProps = {
  view?: EUserRoles[]
  children: ReactNode
}

export default function PermissionGuard(props: TProps): JSX.Element {
  const { view, children } = props

  const { isUser, isTechAdmin, isHr, isSuperHr } = useCurrentUserContext()
  function matchRole(roleId: EUserRoles) {
    if (roleId === EUserRoles.USER) return isUser
    if (roleId === EUserRoles.HR) return isHr
    if (roleId === EUserRoles.SUPER_HR) return isSuperHr
    if (roleId === EUserRoles.TECH_ADMIN) return isTechAdmin

    return false
  }

  if (view?.find(matchRole)) {
    return <>{children}</>
  }

  return <></>
}
