type AccessParams = {
  isHr: boolean
  isSuperHr: boolean
  isTechAdmin: boolean
  isUser: boolean
  route: string
}

export function determineAccess({
  isHr,
  isSuperHr,
  isTechAdmin,
  isUser,
  route,
}: AccessParams) {
  const pageType = (() => {
    if (route.toLocaleLowerCase().startsWith("/public")) {
      return "public"
    }

    if (route === "" || route === "/") {
      return "empty"
    }

    if (route.toLocaleLowerCase().startsWith("/certificates")) {
      return "certificates"
    }

    return "private"
  })()

  // Authenticated rules
  if (isHr || isSuperHr || isTechAdmin || isUser) {
    const shouldRedirect = pageType === "public" || pageType === "empty"
    return { authorized: true, redirectTo: shouldRedirect ? "/certificates" : null }
  }

  // Guest rules
  switch (pageType) {
    case "public":
      return { authorized: false, redirectTo: null }
    case "empty":
    case "certificates":
    case "private":
      return { authorized: false, redirectTo: "/public/login" }
    default:
      return { authorized: false, redirectTo: "/public/login" }
  }
}
