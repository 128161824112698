import { useEffect, useContext, createContext, ReactNode } from "react"
import { useTranslation } from "react-i18next"

import { useCurrentUser } from "hooks/data"
import { ELanguages, EUserRoles } from "src/constants"
import { IUser } from "types"

type TGetRolesReturn = {
  isUser: boolean
  isTechAdmin: boolean
  isHr: boolean
  isSuperHr: boolean
}

export function getRoles(
  roles: Omit<IUser["SysRoles"][number], "Code">[]
): TGetRolesReturn {
  const roleIds = roles.map(({ ID }) => ID)

  return {
    isUser: roleIds.length === 1,
    isHr: roleIds.length === 2 && roleIds.includes(EUserRoles.HR),
    isSuperHr: roleIds.length === 4,
    isTechAdmin:
      roleIds.length === 2 && roleIds.includes(EUserRoles.TECH_ADMIN),
  }
}

const DEV_USERS = [
  // 'techadmin@skriba.ch',
  "dev@skriba.ch",
  "frodo@skriba.ch",
  "alex@skriba.ch",
  "anatolie@skriba.ch",
  "chris@skriba.ch",
  "dimitris@skriba.ch",
  "soleil@skriba.ch",
  
  "frodo+dev@skriba.ch",
  "alex+dev@skriba.ch",
  "anatolie+dev@skriba.ch",
  "chris+dev@skriba.ch",
  "dimitris+dev@skriba.ch",
  "soleil+dev@skriba.ch",
]

type TContext = {
  currentUser?: IUser
  UILanguage: ELanguages
  roles: EUserRoles[]
  isUser: boolean
  isTechAdmin: boolean
  isHr: boolean
  isSuperHr: boolean
  isDev: boolean
}

const CurrentUserContext = createContext<TContext | null>(null)

type TProps = {
  children: ReactNode
}

function CurrentUserProvider({ children }: TProps): JSX.Element {
  const useCurrentUserQuery = useCurrentUser()
  const { i18n } = useTranslation()

  const roles = useCurrentUserQuery.data?.SysRoles.map(({ ID }) => ID) ?? []

  useEffect(() => {
    if (useCurrentUserQuery.data) {
      const userLanguage = useCurrentUserQuery.data.CatSprache.ID
      if (i18n.language !== userLanguage) {
        void i18n.changeLanguage(userLanguage)
      }
    }
  }, [useCurrentUserQuery, i18n])

  const isDev = DEV_USERS.includes(useCurrentUserQuery.data?.Email ?? "")

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser: useCurrentUserQuery.data,
        UILanguage: useCurrentUserQuery.data?.CatSprache.ID ?? ELanguages.EN,
        isDev,
        roles,
        ...getRoles(useCurrentUserQuery.data?.SysRoles ?? []),
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  )
}

function useCurrentUserContext(): TContext {
  const ctx = useContext(CurrentUserContext)

  if (!ctx) {
    throw new Error(
      "useCurrentUserContext must be used within CurrentUserProvider."
    )
  }

  return ctx
}

export { useCurrentUserContext, CurrentUserProvider }
