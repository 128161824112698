import * as React from "react"

function UserLock(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="14.2462"
        y="15.0618"
        width="7.25378"
        height="6.00302"
        fill="black"
        stroke="black"
        strokeLinejoin="round"
      />
      <path
        d="M19.7491 14.8114C19.7491 15.9371 19.7491 13.6857 19.7491 13.6857C19.7491 12.6495 18.9091 11.8096 17.873 11.8096C16.8368 11.8096 15.9968 12.6495 15.9968 13.6857V15.5618"
        stroke="black"
      />
      <path
        d="M13.2674 7.40483C13.2674 9.56141 11.5191 11.3097 9.36254 11.3097C7.20596 11.3097 5.4577 9.56141 5.4577 7.40483C5.4577 5.24825 7.20596 3.5 9.36254 3.5C11.5191 3.5 13.2674 5.24825 13.2674 7.40483Z"
        stroke="black"
      />
      <path
        d="M5.93658 4.95776C6.75229 6.09976 9.36257 8.09009 13.278 6.91547"
        stroke="black"
      />
      <path
        d="M13.2779 14.048C12.3557 13.3128 11.0918 12.7886 9.36249 12.7886C3.57425 12.7886 3 18.6617 3 18.6617H12.7885"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UserLock
