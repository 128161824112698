import * as React from "react"

function SvgSmileyIndifferent(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="currentColor"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M.5 12a11.5 11.5 0 1023 0 11.5 11.5 0 10-23 0z" />
        <path d="M8.5 7.75a.25.25 0 11-.25.25.25.25 0 01.25-.25M15.5 7.75a.25.25 0 10.25.25.25.25 0 00-.25-.25M5.5 15.5h13" />
      </g>
    </svg>
  )
}

export default SvgSmileyIndifferent
