import * as React from "react"

function SvgLogin(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M131.752 43.75a67.083 67.083 0 100 52.5M67.083 46.667L43.75 70l23.333 23.333M43.75 70h93.333"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgLogin
