import { useState, useContext, createContext, useCallback } from "react"
import type { ReactNode } from "react"

interface IContext {
  sidebarExpanded: boolean
  expandSidebar: () => void
  collapseSidebar: () => void
  contactFormShown: boolean
  showContactForm: () => void
  hideContactForm: () => void
}

const SidebarContext = createContext<IContext | undefined>(undefined)

interface Props {
  children: ReactNode
}

function SidebarProvider({ children }: Props): JSX.Element {
  const [expanded, setExpanded] = useState(true)
  const expandSidebar = useCallback(() => setExpanded(true), [setExpanded])
  const collapseSidebar = useCallback(() => setExpanded(false), [setExpanded])

  const [contactFormShown, setContactFormShown] = useState(false)
  const showContactForm = useCallback(
    () => setContactFormShown(true),
    [setContactFormShown]
  )
  const hideContactForm = useCallback(
    () => setContactFormShown(false),
    [setContactFormShown]
  )

  return (
    <SidebarContext.Provider
      value={{
        sidebarExpanded: expanded,
        expandSidebar,
        collapseSidebar,
        contactFormShown,
        showContactForm,
        hideContactForm,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

function useSidebarContext(): IContext {
  const ctx = useContext(SidebarContext)

  if (ctx == null) {
    throw new Error("useSidebarContext must be used within SidebarProvider.")
  }

  return ctx
}

export { useSidebarContext, SidebarProvider }
