/* eslint-disable import/order */
import * as React from 'react';
import { ConfirmDialogContextProps, ConfirmDialogContext } from '@/components/elements/ConfirmContext/ConfirmProvider';

export function useConfirm(options: Omit<ConfirmDialogContextProps, 'onConfirm' | 'onCancel'>) {
  const context = React.useContext(ConfirmDialogContext);

  if (!context) {
    throw new Error('useConfirm must be used within a ConfirmDialogProvider');
  }

  const getConfirm = React.useCallback(() => {
    return context.openConfirmDialog(options);
  }, [context, options]);

  return getConfirm;
}
