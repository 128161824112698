const SvgBook = (props) => (
  <svg
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    width="100%"
    {...props}
  >
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M36 8.588a59.708 59.708 0 0 1 8.986-.744 1 1 0 0 1 1.014 1v31.532a1 1 0 0 1-.986 1C28.8 41.582 24 46.838 24 46.838V13.306s-4.8-5.25-20.986-5.462a1 1 0 0 0-1.014 1v31.532a1 1 0 0 0 .986 1C19.2 41.582 24 46.838 24 46.838"
        strokeWidth={2}
      />
      <path
        d="M24 45.838a13.92 13.92 0 0 1 12-12V2.162a1 1 0 0 0-1.324-.946C24.694 4.624 24 11.898 24 12.838"
        strokeWidth={2}
      />
    </g>
  </svg>
)

export default SvgBook
