import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query"

import { definitions, operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { companyKeys } from "./keys"
import { TAltCompany } from "./useAltCompanies"

type TData = TAltCompany
type TError = unknown
type TPayload = Required<definitions["CompanyAlternativeNameDto"]>
type TContext = {
  previousAltCompany?: TAltCompany
}
type TQueryParams =
  operations["CompanyAlternativeNames_Post"]["parameters"]["query"]
type TArgs = {
  queryParams?: TQueryParams
  options?: UseMutationOptions<TData, TError, TPayload, TContext>
}
type THook = (
  args: TArgs
) => UseMutationResult<TData, TError, TPayload, TContext>

export const useUpdateAltCompany: THook = ({ queryParams, options }) => {
  const queryClient = useQueryClient()
  let key: ReturnType<typeof companyKeys.oneAlt>

  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(
    (payload: TPayload) => {
      key = companyKeys.oneAlt(payload.CompanyAlternativeNameID)

      return fetchJson<TData, false, TQueryParams>(
        // @ts-ignore
        `CompanyAlternativeNames/${payload.CompanyAlternativeNameID}`,
        {
          method: "PUT",
          body: payload,
          queryParams: {
            renderSpracheId: "de",
            ...queryParams,
          },
        }
      )
    },
    {
      async onMutate(payload) {
        toastId = toast.loading(t("updating"))

        await queryClient.cancelQueries(key)

        const previousAltCompany = queryClient.getQueryData<TAltCompany>(key)

        if (previousAltCompany) {
          queryClient.setQueryData(key, payload)
        }

        return { previousAltCompany }
      },
      onError(...args) {
        const [, , context] = args

        if (context?.previousAltCompany) {
          queryClient.setQueryData(key, context.previousAltCompany)
        }

        toast.error(t("error"), { id: toastId })

        void options?.onError?.(...args)
      },
      onSuccess(...args) {
        toast.success(t("changesSaved"), { id: toastId })

        void options?.onSuccess?.(...args)
      },
      ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
    }
  )
}
