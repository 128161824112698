import { Listbox } from "@headlessui/react"
import classNames from "classnames"
import { TFunction } from "i18next"
import { useEffect, useState, ElementType, ReactNode } from "react"
import { useTranslation } from "react-i18next"

import {
  Checkmark as CheckmarkIcon,
  ChevronDown as ChevronDownIcon,
} from "assets/icons"
import { ExtractProps } from "types/helpers"

import FloatPlacement from "./FloatPlacement"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ISelectItem<TValue, TPrimary = string, TSecondary = string> {
  value: TValue
  primary: ReactNode | string
  secondary?: ReactNode
  disabled?: boolean
}

type TProps<TValue, TPrimary, TSecondary> = Omit<
  ExtractProps<typeof Listbox>,
  "value"
> & {
  label?: TFunction | string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: ElementType<any>
  preselectedItem?: ISelectItem<TValue, TPrimary, TSecondary>
  preselectedItems?: ISelectItem<TValue, TPrimary, TSecondary>[]
  selectItems?: ISelectItem<TValue, TPrimary, TSecondary>[]
  onChange?: (item: ISelectItem<TValue, TPrimary, TSecondary>) => void
  statusIndicator?: boolean
  disabled?: boolean
  truncateText?: boolean
  hideLabel?: boolean
  multi?: true
  wrapperClassName?: string
  children?: ({ reset }: { reset: () => void }) => void
}

/** @deprecated */

// TODO:
// Consider refactoring when the opportunity arises:
// - Preselected item must be a stable value (string or number) by default - not an object that gets recreated
// - Think of a better way to offer multi-select capabilities
// - Re-evaluate truncateText prop name (flip it to false by default)
export default function Select<
  TValue = string,
  TPrimary = string,
  TSecondary = string
>(props: TProps<TValue, TPrimary, TSecondary>): JSX.Element {
  const {
    label,
    as = "div",
    disabled,
    onChange,
    preselectedItem,
    preselectedItems,
    selectItems = [],
    statusIndicator,
    truncateText = false,
    hideLabel,
    multi,
    wrapperClassName = "",
    children,
  } = props

  const { t } = useTranslation("selectComponent")

  const placeholderSelectItem = {
    value: "",
    primary: t("placeholder"),
    secondary: "",
  } as unknown as ISelectItem<TValue, TPrimary, TSecondary>

  const [selectedItem, setSelectedItem] = useState<
    ISelectItem<TValue, TPrimary, TSecondary>
  >(placeholderSelectItem)
  const [selectedItems, setSelectedItems] = useState<
    ISelectItem<TValue, TPrimary, TSecondary>[]
  >([placeholderSelectItem])

  function reset() {
    if ((selectedItem.value as unknown as string) !== "") {
      setSelectedItem(placeholderSelectItem)
    }
  }

  useEffect(() => {
    if (preselectedItem) {
      setSelectedItem(preselectedItem)
    }
    if (preselectedItems) {
      setSelectedItems(preselectedItems)
    }
  }, [preselectedItem, preselectedItems])

  return (
    <>
      {children?.({ reset })}
      <Listbox
        value={multi ? selectedItems : selectedItem}
        onChange={(item) => {
          // console.log("Select: onChange: item:", item)
          if (multi) {
            const alreadySelected = selectedItems.find(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ({ value }) => value === item.value
            )

            const nextState = alreadySelected
              ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                selectedItems.filter(({ value }) => value !== item.value)
              : ([...selectedItems, item] as ISelectItem<
                  TValue,
                  TPrimary,
                  TSecondary
                >[])

            setSelectedItems(nextState)

            if (onChange) {
              onChange(nextState)
            }
          } else {
            setSelectedItem(item as ISelectItem<TValue, TPrimary, TSecondary>)
            if (onChange) {
              onChange(item as ISelectItem<TValue, TPrimary, TSecondary>)
            }
          }
        }}
        {...{ disabled, as }}
      >
        <div className={classNames([wrapperClassName, "relative"])}>
          <Listbox.Label
            className={classNames([
              "mb-2 block text-sm font-[600] text-black",
              hideLabel && "sr-only",
            ])}
          >
            {label}
          </Listbox.Label>
          <FloatPlacement>
            <Listbox.Button
              className={classNames([
                "relative",
                "h-10 w-full",
                "py-2 pl-3 pr-10",
                "cursor-default rounded-md",
                "ring-1 ring-inset ring-black",
                "focus:outline-none focus:ring-2 focus:ring-ocean",
                "text-left",
                disabled ? "bg-gray-200 opacity-50" : "bg-white",
              ])}
            >
              <div className="flex items-center">
                {statusIndicator && (
                  <span
                    aria-label="indicator"
                    className={classNames([
                      "mr-2",
                      "inline-block",
                      "h-2 w-2",
                      "flex-shrink-0",
                      "rounded-full",
                      "bg-green-400",
                    ])}
                  />
                )}
                <div
                  className={classNames([
                    "inline-flex w-full",
                    truncateText ? "truncate" : null,
                  ])}
                >
                  {multi ? (
                    <span className="truncate">
                      {selectedItems.map(({ primary }) => primary).join(", ")}
                    </span>
                  ) : (
                    <div
                      className="truncate"
                      dangerouslySetInnerHTML={{
                        __html: selectedItem.primary as string,
                      }}
                    ></div>
                  )}
                  {!multi && selectedItem.secondary && (
                    <span
                      className={classNames([
                        "ml-2",
                        "truncate",
                        "text-gray-500",
                      ])}
                    >
                      {selectedItem.secondary}
                    </span>
                  )}
                </div>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[13px]">
                  <ChevronDownIcon className="icon" />
                </span>
              </div>
            </Listbox.Button>
            <Listbox.Options
              static
              className={classNames([
                "z-10 mt-1",
                "max-h-80 w-full",
                "overflow-auto rounded-md bg-white",
                "py-1",
                "text-base shadow-lg",
                "border border-black",
                "focus:outline-none",
              ])}
            >
              {selectItems.map((selectItem, idx) => (
                <Listbox.Option
                  // WORKAROUND:
                  // Use 'primary' (description text) and item index
                  // as a key since 'value' could be an object
                  // and always requiring an additional id property
                  // at call site (so it could be used as a key here)
                  // seems to be a bit of an overkill.
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${selectItem.primary as unknown as string}__${idx}`}
                  className={({ active, disabled: isOptionDisabled }) =>
                    classNames([
                      "relative cursor-default select-none py-2 pl-4 pr-9",
                      active && "bg-ocean",
                      isOptionDisabled ? "bg-gray-200" : null,
                    ])
                  }
                  value={selectItem}
                  disabled={selectItem.disabled}
                >
                  {() => {
                    const isSelected = multi
                      ? selectedItems.find(
                          ({ value }) => value === selectItem.value
                        )
                      : selectItem.value === selectedItem.value
                    return (
                      <>
                        <div className="flex">
                          <div
                            className={classNames([
                              "block",
                              isSelected ? "font-semibold" : "font-normal",
                              truncateText ? "truncate" : "",
                            ])}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: selectItem.primary as string,
                              }}
                            ></div>
                          </div>
                          {selectItem.secondary && (
                            <span
                              className={classNames([
                                "ml-2",
                                "text-gray-500",
                                truncateText ? "truncate" : "",
                              ])}
                            >
                              {selectItem.secondary}
                            </span>
                          )}
                        </div>

                        {isSelected && (
                          <span
                            className={classNames([
                              "text-black",
                              "absolute",
                              "inset-y-0",
                              "right-0",
                              "ml-4 flex",
                              "items-center pr-4",
                            ])}
                          >
                            <CheckmarkIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        )}
                      </>
                    )
                  }}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </FloatPlacement>
        </div>
      </Listbox>
    </>
  )
}
