import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { fetchJson } from "utils/network"

import { IDepartment } from "./useDepartments"

type TData = IDepartment
type TError = unknown
type TPayload = IDepartment
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

const updateDepartment = (payload: TPayload) =>
  // @ts-ignore
  fetchJson<TPayload>(`CatAbteilungen/${payload.CatAbteilungID}`, {
    method: "PUT",
    body: payload,
  })

export const useDepartmentMutation: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(updateDepartment, {
    onMutate(...args) {
      toastId = toast.loading(t("updating"))

      options?.onMutate?.(...args)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("changesSaved"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
