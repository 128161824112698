import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"
import { Promisable } from "type-fest"

import { fetchBlob } from "utils/network"

type TData = Promisable<ReturnType<typeof fetchBlob>>
type TError = unknown
type TPayload = FormData
type TOptions = UseMutationOptions<TData, TError, TPayload>
type TReturn = UseMutationResult<TData, TError, TPayload>

const uploadFont = (payload: TPayload) =>
  fetchBlob("schriftarten", {
    method: "POST",
    body: payload,
  })

export function useUploadFont(options?: TOptions): TReturn {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  // @ts-ignore
  return useMutation(uploadFont, {
    onMutate(...args) {
      toastId = toast.loading(t("inProgress"))

      options?.onMutate?.(...args)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("done"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onError", "onSuccess"]),
  })
}
