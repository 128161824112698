import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"
import { Object, Any } from "ts-toolbelt"

import { definitions, operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { companyKeys } from "."

export type TAltCompany = Object.Required<
  definitions["CompanyAlternativeNameDto"],
  Any.Keys<definitions["CompanyAlternativeNameDto"]>,
  "deep"
>

type TData = TAltCompany[]
type TQueryParams =
  operations["CompanyAlternativeNames_GetList"]["parameters"]["query"]
type TArgs = {
  queryParams?: TQueryParams
  options?: UseQueryOptions<TData>
}
type THook = (args: TArgs) => UseQueryResult<TData>

export const useAltCompanies: THook = ({ options, queryParams }) =>
  useQuery(
    companyKeys.allAlt,
    () =>
      fetchJson<TData, false, TQueryParams>("companyalternativenames", {
        queryParams,
      }),
    // @ts-ignore
    {
      enabled: Boolean(queryParams?.companyId),
      ...options,
    }
  )
