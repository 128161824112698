import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useCurrentUserContext } from "context/CurrentUserContext"

import Button from "../elements/Button"
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "../ui/dialog"

const FirstLoginToast = () => {
  const { t } = useTranslation("firstLogin")

  const { currentUser, isUser } = useCurrentUserContext()
  const [isFirstLogin, setIsFirstLogin] = useState(false)

  useEffect(() => {
    if (isUser && currentUser) {
      const userId = currentUser?.BenutzerID
      const tokenKey = `firstLogin${userId}`
      console.log("tokenKey", tokenKey)

      if (!localStorage.getItem(tokenKey)) {
        localStorage.setItem(tokenKey, "true")
        setIsFirstLogin(true)
        console.log("This is your first login!")
      }
    }
  }, [currentUser, isUser])

  return (
    <Dialog open={isFirstLogin} onOpenChange={setIsFirstLogin}>
      <DialogContent className="w-[700px] max-w-3xl">
        <DialogHeader title={t("title")} />
        <div>
          <p>
            {t("subtitle")}
            &nbsp;
            <a
              href={t("link") ?? ""}
              target="_blank"
              rel="noreferrer"
              className="text-electricBlue underline"
            >
              {t("linkText")}
            </a>
          </p>
          <br />
          <p className="mb-2">{t("videoText")}</p>
          <iframe
            src={t("videoLink") ?? ""}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube video player"
            width="652"
            height="370"
          ></iframe>
        </div>

        <DialogFooter>
          <Button
            onClick={() => {
              setIsFirstLogin(false)
            }}
          >
            {t("getStarted")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default FirstLoginToast
