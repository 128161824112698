import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { fetchJson } from "utils/network"

import { TClosingStatement } from "./useClosingStatement"

type TData = TClosingStatement
type TError = unknown
type TPayload = TClosingStatement
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

const createClosingStatement = (payload: TPayload) =>
  fetchJson<TData>(
    //@ts-ignore
    `CatSchlussformelOptionen/${payload.CatSchlussformelOptionID}`,
    { method: "PUT", body: payload }
  )

export const useUpdateClosingStatement: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(createClosingStatement, {
    onMutate(variables) {
      toastId = toast.loading(t("updating"))

      options?.onMutate?.(variables)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("changesSaved"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
