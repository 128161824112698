import * as LabelPrimitive from "@radix-ui/react-label"
import * as React from "react"

import cn from "utils/classMerge"

export const labelClassnames = cn(
  "text-sm font-semibold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
)

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>>
  (({ className, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
      className={cn(labelClassnames, className)}
    {...props}
  />
))
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
