import { SVGProps } from "react"

type TProps = {
  svgProps?: SVGProps<SVGSVGElement>
  pathProps?: SVGProps<SVGPathElement>
}

function SvgWarning({ pathProps, svgProps }: TProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      {...svgProps}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" {...pathProps} />
      <path
        d="M12 2c5.523 0 10 4.477 10 10a10 10 0 0 1 -19.995 .324l-.005 -.324l.004 -.28c.148 -5.393 4.566 -9.72 9.996 -9.72zm.01 13l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-.01 -8a1 1 0 0 0 -.993 .883l-.007 .117v4l.007 .117a1 1 0 0 0 1.986 0l.007 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z"
        stroke-width="0"
        fill="currentColor"
        {...pathProps}
      />
    </svg>
  )
}

export default SvgWarning
