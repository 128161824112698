import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query"

import { fetchJson } from "utils/network"

import { locationKeys } from "."
import { ILocation } from "./useLocations"

type TData = ILocation
type TError = unknown
type TPayload = ILocation
type TContext = {
  previousLocations?: ILocation[]
}
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload, TContext>
) => UseMutationResult<TData, TError, TPayload, TContext>

const updateLocation = (payload: TPayload) =>
  // @ts-ignore
  fetchJson(`CatStandorte/${payload.CatStandortID}`, {
    method: "PUT",
    body: payload,
  })

export const useLocationMutation: THook = (options) => {
  const queryClient = useQueryClient()
  const key = locationKeys.all

  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(updateLocation, {
    async onMutate(payload) {
      toastId = toast.loading(t("updating"))

      await queryClient.cancelQueries(key)

      const previousLocations = queryClient.getQueryData<ILocation[]>(key)

      if (previousLocations) {
        queryClient.setQueryData(
          key,
          previousLocations.map((location) => {
            if (location.CatStandortID === payload.CatStandortID) {
              return payload
            }
            return location
          })
        )
      }

      return { previousLocations }
    },
    onError(...args) {
      const [, , context] = args

      if (context?.previousLocations) {
        queryClient.setQueryData(key, context.previousLocations)
      }

      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      const [data, , context] = args

      if (context?.previousLocations) {
        queryClient.setQueryData(
          key,
          context.previousLocations.map((location) => {
            if (location.CatStandortID === data.CatStandortID) {
              return data
            }
            return location
          })
        )
      }

      toast.success(t("changesSaved"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
