import classNames from "classnames"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { SetOptional } from "type-fest"

import { getRoles, useCurrentUserContext } from "context/CurrentUserContext"
import { useFunctions } from "hooks/data"
import { EGenders, EUserRoles } from "src/constants"
import { IUser } from "types"
import { fetchGetUserPictureUrl } from "utils/network"

type TUserCardProps = {
  user: IUser
  onClick: () => void
  disabled?: boolean
}

export const COLORS = {
  [EUserRoles.USER]: "bg-yellow-200 text-yellow-800",
  [EUserRoles.HR]: "bg-blue-200 text-blue-800",
  [EUserRoles.SUPER_HR]: "bg-green-200 text-green-800",
  [EUserRoles.TECH_ADMIN]: "bg-gray-200 text-gray-800",
}

export function getMostPriviligedRole(
  roles?: SetOptional<IUser["SysRoles"][number], "Code">[]
): SetOptional<IUser["SysRoles"][number], "Code"> | undefined {
  if (!roles) return

  const { isUser, isHr, isSuperHr, isTechAdmin } = getRoles(roles)

  if (isTechAdmin) {
    return roles.find(({ ID }) => ID === EUserRoles.TECH_ADMIN)
  }

  if (isSuperHr) {
    return roles.find(({ ID }) => ID === EUserRoles.SUPER_HR)
  }

  if (isHr) {
    return roles.find(({ ID }) => ID === EUserRoles.HR)
  }

  if (isUser) {
    return roles.find(({ ID }) => ID === EUserRoles.USER)
  }
}

export default function UserCard(props: TUserCardProps): JSX.Element {
  const { user, onClick, disabled } = props

  const { t } = useTranslation("userRoles")

  const { UILanguage } = useCurrentUserContext()

  const mostPrivilegedRole = getMostPriviligedRole(user.SysRoles)

  const functionsQuery = useFunctions()

  const userJobTitle = useMemo(
    () =>
      functionsQuery.data?.find(
        ({ CatFunktionID }) => CatFunktionID === user.CatFunktionID
      )?.[
        String(user.CatGeschlechtID) === EGenders.FEMALE
          ? "BezeichnungWeiblichML"
          : "BezeichnungMaennlichML"
      ][UILanguage],
    [functionsQuery.data, user, UILanguage]
  )

  return (
    <button
      type="button"
      className={classNames([
        "flex justify-between gap-4",
        "rounded-md",
        "border border-black p-6",
        "disabled:cursor-default",
      ])}
      {...{ onClick, disabled }}
    >
      <div>
        <div className="flex justify-start gap-4">
          <div className="flex items-center text-left font-semibold">
            {user.Vorname} {user.Nachname}
          </div>
          {mostPrivilegedRole?.Code && (
            <span
              className={classNames([
                "grid place-content-center",
                "rounded-full px-6",
                "py-1",
                "font-semibold",
                COLORS[mostPrivilegedRole.ID],
              ])}
            >
              {t(mostPrivilegedRole.Code)}
            </span>
          )}
        </div>
        <div className="mt-4 flex justify-start text-gray-400">
          {userJobTitle}
        </div>
      </div>
      <div>
        {user.ProfileFileId && (
          <img
            crossOrigin="use-credentials"
            src={fetchGetUserPictureUrl({ userId: user.BenutzerID })}
            alt="Current user"
            className={classNames([
              "ml-auto ",
              "h-12 w-12",
              "rounded-full",
              "border border-gray-400 object-cover",
            ])}
          />
        )}

        {!user.ProfileFileId && (
          <div
            className={classNames([
              "ml-auto flex h-12",
              "w-12 items-center",
              "justify-center",
              "rounded-full",
              "text-lg",
              "capitalize",
              mostPrivilegedRole ? COLORS[mostPrivilegedRole.ID] : null,
            ])}
          >
            {user.Vorname.charAt(0)} {user.Nachname.charAt(0)}
          </div>
        )}
      </div>
    </button>
  )
}
