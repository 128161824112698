import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { IUser, TODataParams } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { userKeys } from "./keys"

export type TUseUsersQueryParams =
  operations["Benutzer_GetList"]["parameters"]["query"] & TODataParams

type TData = IUser[]
type TError = unknown
type THook = (
  params?: TUseUsersQueryParams,
  options?: UseQueryOptions<TData, TError>
) => UseQueryResult<TData, TError>

export const useUsers: THook = (params, options) =>
  useQuery(
    userKeys.all(params),
    () =>
      fetchJson<TData, false, TUseUsersQueryParams>(`benutzer`, {
        queryParams: {
          filterInactiveAcounts: true,
          includeAuthorizationInfo: false,
          ...params,
        },
      }),
    // @ts-ignore
    options
  )
