import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import type { TLanguageCodes, TODataParams } from "types"
import { fetchJson } from "utils/network"

export enum ECertificateTypes {
  WORK = 1,
  INTERMEDIARY_WORK = 2,
  CONFIRMATION = 3,
  INTERNSHIP = 4,
  INTERMEDIARY_CONFIRMATION = 5,
  INTERMEDIARY_INTERNSHIP = 6,
}

interface ISharedProps {
  Bezeichnung: string
  BezeichnungML: Record<TLanguageCodes, string>
  Kuerzel: string
  KuerzelML: Record<TLanguageCodes, string>
  IsActive: boolean
}

export interface IWorkCertificate extends ISharedProps {
  CatZeugnisTypID: ECertificateTypes.WORK
  Code: "arbeit"
}
export interface IIntermediaryWorkCertificate extends ISharedProps {
  CatZeugnisTypID: ECertificateTypes.INTERMEDIARY_WORK
  Code: "zwischen"
}
export interface IConfirmation extends ISharedProps {
  CatZeugnisTypID: ECertificateTypes.CONFIRMATION
  Code: "bestaetigung"
}
export interface IIntermediaryConfirmation extends ISharedProps {
  CatZeugnisTypID: ECertificateTypes.INTERMEDIARY_CONFIRMATION
  Code: "current.bestaetigung"
}
export interface IInternship extends ISharedProps {
  CatZeugnisTypID: ECertificateTypes.INTERNSHIP
  Code: "lehr"
}
export interface IIntermediaryInternship extends ISharedProps {
  CatZeugnisTypID: ECertificateTypes.INTERMEDIARY_INTERNSHIP
  Code: "current.lehr"
}

export type ICertificateType =
  | IWorkCertificate
  | IIntermediaryWorkCertificate
  | IConfirmation
  | IIntermediaryConfirmation
  | IInternship
  | IIntermediaryInternship

type TData = ICertificateType[]
type TArgs = {
  options?: UseQueryOptions<TData>
  queryParams?: TODataParams
}
type THook = (args?: TArgs) => UseQueryResult<TData>

export const useCertificateTypes: THook = ({ options, queryParams } = {}) =>
  useQuery(
    ["CERTIFICATE_TYPES", queryParams],
    () =>
      fetchJson<TData>("catzeugnistypen", {
        queryParams: {
          $filter: "IsActive eq true",
          ...queryParams,
        },
      }),
    // @ts-ignore
    {
      select(data) {
        const sortedLogically = []

        const intermediaryWork = data.find(
          ({ CatZeugnisTypID }) =>
            CatZeugnisTypID === ECertificateTypes.INTERMEDIARY_WORK
        )
        const work = data.find(
          ({ CatZeugnisTypID }) => CatZeugnisTypID === ECertificateTypes.WORK
        )
        const intermediaryInternship = data.find(
          ({ CatZeugnisTypID }) =>
            CatZeugnisTypID === ECertificateTypes.INTERMEDIARY_INTERNSHIP
        )
        const internship = data.find(
          ({ CatZeugnisTypID }) =>
            CatZeugnisTypID === ECertificateTypes.INTERNSHIP
        )
        const intermediaryConfirmation = data.find(
          ({ CatZeugnisTypID }) =>
            CatZeugnisTypID === ECertificateTypes.INTERMEDIARY_CONFIRMATION
        )
        const confirmation = data.find(
          ({ CatZeugnisTypID }) =>
            CatZeugnisTypID === ECertificateTypes.CONFIRMATION
        )

        if (intermediaryWork) {
          sortedLogically.push(intermediaryWork)
        }
        if (work) {
          sortedLogically.push(work)
        }
        if (intermediaryInternship) {
          sortedLogically.push(intermediaryInternship)
        }
        if (internship) {
          sortedLogically.push(internship)
        }
        if (intermediaryConfirmation) {
          sortedLogically.push(intermediaryConfirmation)
        }
        if (confirmation) {
          sortedLogically.push(confirmation)
        }

        return sortedLogically as TData
      },
      ...options,
    }
  )
