import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { functionKeys } from "./keys"
import { DEFAULT_REQUEST_FUNCTION_PARAMS } from "./useFunctionMutation"
import { IFunction } from "./useFunctions"

export type TUseFunctionQueryParams =
  operations["CatFunktionen_GetById"]["parameters"]["query"]

type TData = IFunction
type TArgs = {
  id?: number
  params?: TUseFunctionQueryParams
  options?: UseQueryOptions<TData>
}
type THook = ({ id, params, options }: TArgs) => UseQueryResult<TData>

const getFunction = (params: TUseFunctionQueryParams, id?: number) =>
  id
    ? // @ts-ignore
      fetchJson<TData, false, TUseFunctionQueryParams>(`CatFunktionen/${id}`, {
        queryParams: params,
      })
    : Promise.reject(new Error("Missing id"))

export const useFunction: THook = ({ id, params, options }: TArgs) => {
  const paramsWithDefaults = { ...DEFAULT_REQUEST_FUNCTION_PARAMS, ...params }

  return useQuery(
    functionKeys.one(id ?? 0, paramsWithDefaults),
    () => getFunction(paramsWithDefaults, id),
    // @ts-ignore
    {
      ...options,
      enabled: Boolean(id),
    }
  )
}
