import * as React from "react"

function SvgSupport(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M48.125 56.828a21.887 21.887 0 1126.612 21.339A5.874 5.874 0 0070 83.854v6.493M70 104.953a1.458 1.458 0 11-1.458 1.459A1.458 1.458 0 0170 104.953"
          strokeWidth={5.83333}
        />
        <path
          d="M2.917 70.006a67.083 67.083 0 10134.166 0 67.083 67.083 0 10-134.166 0z"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgSupport
