import { TCertificatesForTableQueryParams } from "./useCertificatesForTableInfinite"
import { TCertificatesRequestsForTableQueryParams } from "./useCertificatesRequestsForTableInfinite"

export const certificateKeys = {
  all: ["CERTIFICATES"] as const,
  allForTableInfinite: (
    params?: TCertificatesForTableQueryParams
  ):
    | ["CERTIFICATES_FOR_TABLE_INFINITE"]
    | ["CERTIFICATES_FOR_TABLE_INFINITE", TCertificatesForTableQueryParams] => {
    if (params) {
      return ["CERTIFICATES_FOR_TABLE_INFINITE", params]
    }

    return ["CERTIFICATES_FOR_TABLE_INFINITE"]
  },
  allRequestsForTableInfinite: (
    params?: TCertificatesForTableQueryParams
  ):
    | ["CERTIFICATES_REQUESTS_FOR_TABLE_INFINITE"]
    | ["CERTIFICATES_REQUESTS_FOR_TABLE_INFINITE", TCertificatesRequestsForTableQueryParams] => {
    if (params) {
      return ["CERTIFICATES_REQUESTS_FOR_TABLE_INFINITE", params]
    }

    return ["CERTIFICATES_REQUESTS_FOR_TABLE_INFINITE"]
  },
  one: (id?: number) => [...certificateKeys.all, id] as const,
}
