import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query"

import { fetchJson } from "utils/network"

import { certificateKeys } from "./keys"
import { ICertificate } from "./useCertificate"

type TData = {
  ZeugnisID?: number
  Schlussformeln: {
    CatSchlussformelOptionID: number
    Werte: []
  }[]
  SchlussformelTexte: {
    SchlussformelTexte: {
      Text: string
    }[]
  }[]
  UseConclusionCustomizedTemplate: boolean | null | undefined
  ConclusionCustomizedTemplate: string | null | undefined
  ConclusionTemplate: string | null | undefined
  ConclusionCustomizedTexte: string | undefined
}

type TError = {
  Message: string
}

type TPayload = {
  certificateId: number | undefined
  payload: {
    Schlussformeln:
      | {
          CatSchlussformelOptionID: number
          Werte: []
        }[]
      | undefined
    UseConclusionCustomizedTemplate: boolean | null | undefined
    ConclusionCustomizedTemplate: string | null | undefined
    ConclusionTemplate: string | null | undefined
    ConclusionCustomizedTexte: string | undefined
  }
}

type TContext = { previousCertificate?: ICertificate }
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload, TContext>
) => UseMutationResult<TData, TError, TPayload, TContext>

export const useConclusionMutation: THook = (options) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(
    ({ certificateId, payload }) =>
      // @ts-ignore
      fetchJson<TData>(`Zeugnisse/${certificateId as number}/PutConclusion`, {
        method: "PUT",
        body: payload,
      }),
    {
      onMutate({ certificateId, payload }) {
        toastId = toast.loading(t("updating"))

        const key = certificateKeys.one(certificateId)

        const previousCertificate = queryClient.getQueryData<ICertificate>(key)

        if (previousCertificate) {
          queryClient.setQueryData(key, {
            ...previousCertificate,
            ...payload,
          })
        }

        return { previousCertificate }
      },
      onError(...args) {
        const [, { certificateId }, ctx] = args

        if (ctx?.previousCertificate) {
          queryClient.setQueryData(
            certificateKeys.one(certificateId),
            ctx.previousCertificate
          )
        }

        toast.error(t("error"), { id: toastId })

        void options?.onError?.(...args)
      },
      onSuccess(...args) {
        const [data] = args

        const key = certificateKeys.one(data.ZeugnisID)
        const previousCertificate = queryClient.getQueryData<ICertificate>(key)

        queryClient.setQueryData(key, {
          ...previousCertificate,
          ...data,
        })
        toast.success(t("changesSaved"), { id: toastId })

        void options?.onSuccess?.(...args)
      },
      ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
    }
  )
}
