import { Float, FloatProps } from "@headlessui-float/react"

export default function FloatPlacement(props: FloatProps) {
  return (
    <Float
      adaptiveWidth
      offset={4}
      flip
      shift
      enter="transition duration-200 ease-out"
      enterFrom="scale-95 opacity-0"
      enterTo="scale-100 opacity-100"
      leave="transition duration-150 ease-in"
      leaveFrom="scale-100 opacity-100"
      leaveTo="scale-95 opacity-0"
      tailwindcssOriginClass
      {...props}
    >
      {props.children}
    </Float>
  )
}
