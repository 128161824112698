// @ts-ignore
import { omit } from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { fetchJson } from "utils/network"

type TData = undefined
type TError = unknown
type TPayload = void
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

export const useLogoutUser: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  // @ts-ignore
  return useMutation(() => fetchJson(`benutzer/logout`), {
    onError(...args) {
      toast.error(t("error"))

      void options?.onError?.(...args)
    },
    ...omit(options, "onError"),
  })
}
