import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query"

import { ELanguages, EUserRoles } from "src/constants"
import { IUser } from "types"
import { fetchJson } from "utils/network"

import { userKeys } from "./keys"
import { EUserRoleCodes } from "./useUserRoles"

type TData = IUser
type TError = unknown
type TPayload = Omit<IUser, "CatSprache" | "SysRoles"> & {
  CatSprache: {
    ID: ELanguages
    DisplayName: string
  }
  SysRoles: {
    ID: EUserRoles
    Code: EUserRoleCodes
  }[]
}
type TContext = {
  previousUser?: IUser
}
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload, TContext>
) => UseMutationResult<TData, TError, TPayload, TContext>

export const useUserMutation: THook = (options) => {
  const queryClient = useQueryClient()
  // let key: ReturnType<typeof userKeys.one>

  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(
    (payload) =>
      // @ts-ignore
      fetchJson<TData>(`Benutzer/${payload.BenutzerID}`, {
        method: "PUT",
        body: payload,
      }),
    {
      async onMutate(payload) {
        toastId = toast.loading(t("updating"))
        const key = userKeys.one(payload.BenutzerID)

        await queryClient.cancelQueries(key)

        const previousUser = queryClient.getQueryData<IUser>(key)

        if (previousUser) {
          queryClient.setQueryData(key, payload)
        }

        void options?.onMutate?.(payload)

        return { previousUser }
      },
      onError(...args) {
        const [, payload, context] = args
        const key = userKeys.one(payload.BenutzerID)

        if (context?.previousUser) {
          queryClient.setQueryData(key, context.previousUser)
        }

        toast.error(t("error"), { id: toastId })

        void options?.onError?.(...args)
      },
      onSuccess(...args) {
        const [data] = args
        const key = userKeys.one(data.BenutzerID)
        queryClient.setQueryData(key, data)

        toast.success(t("changesSaved"), { id: toastId })

        void options?.onSuccess?.(...args)
      },
      ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
    }
  )
}
