import { TUseUsersQueryParams, TUseUsersInfiniteQueryParams } from "."

export const userKeys = {
  all(
    params?: TUseUsersQueryParams
  ): ["USERS", TUseUsersQueryParams] | ["USERS"] {
    if (params) {
      return ["USERS", params]
    }

    return ["USERS"]
  },
  infinite: (
    params: TUseUsersInfiniteQueryParams
  ): ["USERS_INFINITE", TUseUsersInfiniteQueryParams] => [
    "USERS_INFINITE",
    params,
  ],
  one: (id: number): ["USERS", number] => ["USERS", id],
  roles: ["USER_ROLES"] as const,
}
