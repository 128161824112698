import classNames from "classnames"
import { useState, HTMLProps, FocusEvent, useEffect } from "react"
import {
  UseFormRegister,
  Path,
  RegisterOptions,
  // Message, ValidationRule, Validate, ValidateResult,
  FieldValues,
  // FieldValue
} from "react-hook-form"

import {
  AlertCircle as AlertIcon,
  Locked as LockedIcon,
  Unlocked as UnlockedIcon,
} from "assets/icons"

type TOnBlurEvent = FocusEvent<HTMLInputElement | HTMLTextAreaElement>

type TProps<TFieldValues extends FieldValues> = Omit<
  HTMLProps<HTMLInputElement>,
  "onBlur" | "onKeyDown"
> & {
  // id: Path<Partial<TFieldValues>>
  id: Path<TFieldValues>
  label: string
  // register: UseFormRegister<Partial<TFieldValues>>
  register?: UseFormRegister<TFieldValues>
  // @ts-ignore
  registerOptions?: RegisterOptions<Path<TFieldValues>>
  registerFunction?: unknown
  onBlur?: (event: TOnBlurEvent) => void
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
  disabled?: boolean
  minVal?: number
  maxVal?: number
  multiline?: boolean
  rows?: number
  error?: boolean
  withReveal?: boolean
  className?: string
  defaultValue?: string
}
// TODO:
// - Split this component into two: TextInput and TextAreaInput
export default function TextInput<TFieldValues extends FieldValues>(
  props: TProps<TFieldValues>
): JSX.Element {
  const {
    id,
    label,
    register,
    registerOptions = {},
    registerFunction,
    onBlur,
    onKeyDown,
    disabled,
    minVal,
    maxVal,
    multiline,
    rows = 1,
    type: initialType = "text",
    autoComplete,
    error,
    withReveal,
    className,
    defaultValue = "",
    ...other
  } = props
  // console.log(registerOptions)

  const [type, setType] = useState(initialType)
  const [dynamicRows, setDynamicRows] = useState(rows)

  // -TODO: Figure out how to define those properties.
  // @ts-ignore:
  const fieldProps = register(id, registerOptions)

  const inputClassName = classNames([
    "h-10 rounded-lg w-full",
    "border-none ring-1 ring-inset ring-black",
    "focus:ring-2 focus:ring-inset focus:ring-ocean placeholder-gray-400",
    multiline && "h-auto",
    disabled && "cursor-not-allowed bg-gray-200 opacity-50",
    error && "border border-red-500",
    className,
  ])

  function handleOnBlur(event: TOnBlurEvent) {
    void fieldProps.onBlur(event)
    if (onBlur) {
      onBlur(event)
    }
  }

  const sharedProps = {
    id,
    disabled,
    ...(type === "number" && { min: minVal }),
    ...(type === "number" && { max: maxVal }),
    ...fieldProps,
    // registerFunction,
    onBlur: handleOnBlur,
    className: inputClassName,
    onKeyDown,
  }

  useEffect(() => {
    if (!multiline) {
      return
    }

    const getInitialHeight = (inputValue: string) => {
      const idealRowsNumber = Math.round(inputValue.length / 68)
      const clamped = Math.min(Math.max(idealRowsNumber, 1), 5)

      setDynamicRows(clamped)
      return
    }

    getInitialHeight(defaultValue || "")
  }, [multiline, defaultValue])

  return (
    <div className="TextInput flex flex-col">
      {label && (
        <label htmlFor={id} className="labelTop font-[700]">
          {label}
        </label>
      )}
      <div className="relative">
        {multiline ? (
          <textarea
            {...sharedProps}
            {...{ rows: dynamicRows }}
            {...(other as HTMLProps<HTMLTextAreaElement>)}
          />
        ) : (
          <input
            {...sharedProps}
            {...other}
            {...{
              type,
              autoComplete,
            }}
            defaultValue={defaultValue}
          />
        )}

        {error && (
          <div
            data-testid="error-icon"
            className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <AlertIcon
              svgProps={{
                className: "iconL text-red-500",
                "aria-hidden": "true",
              }}
            />
          </div>
        )}

        {withReveal && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 hover:cursor-pointer">
            {type === "text" ? (
              <UnlockedIcon
                className="iconL"
                onClick={() => {
                  setType(type === initialType ? "text" : initialType)
                }}
              />
            ) : (
              <LockedIcon
                className="iconL"
                onClick={() => {
                  setType(type === initialType ? "text" : initialType)
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
