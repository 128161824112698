import * as React from "react"

function Bubbles(props) {
  return (
    <svg
      width="1342"
      height="1105"
      viewBox="0 0 1342 1105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_1161_5715)">
        <circle
          cx="894.62"
          cy="657.62"
          r="247.38"
          fill="url(#paint0_linear_1161_5715)"
          fillOpacity="0.5"
        />
      </g>
      <g filter="url(#filter1_f_1161_5715)">
        <circle
          cx="526.346"
          cy="526.942"
          r="326.346"
          fill="url(#paint1_linear_1161_5715)"
          fillOpacity="0.8"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1161_5715"
          x="447.24"
          y="210.24"
          width="894.76"
          height="894.76"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="100"
            result="effect1_foregroundBlur_1161_5715"
          />
        </filter>
        <filter
          id="filter1_f_1161_5715"
          x="0"
          y="0.596436"
          width="1052.69"
          height="1052.69"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="100"
            result="effect1_foregroundBlur_1161_5715"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1161_5715"
          x1="1084.85"
          y1="514.148"
          x2="779.522"
          y2="830.666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96CA77" />
          <stop offset="1" stopColor="#FDD328" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1161_5715"
          x1="387.301"
          y1="257.85"
          x2="770.9"
          y2="692.978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAFB4" />
          <stop offset="1" stopColor="#68A9FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Bubbles
