import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { ITask } from "hooks/data"
import { IItem, TDateFormat } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

type TQueryParams =
  operations["MitarbeiterBeschaeftigungen_Put"]["parameters"]["query"]

export interface IJob {
  AnzahlMitarbeiter: string
  Aufgaben: ITask[]
  BeschaeftigungID: number
  BeschaeftigungsGrad: number
  CatAnstellungsart: IItem
  CatFunktion: IItem
  CatAbteilung: IItem
  HasUsedAufgaben: boolean
  VonDatum: TDateFormat
  IsActive?: boolean
}

type TData = IJob
type TError = unknown
type TVariables = {
  employeeId: number
  certificateId: number
  payload: IJob
  showToast?: true
}
type THook = (
  options?: UseMutationOptions<TData, TError, TVariables>
) => UseMutationResult<TData, TError, TVariables>

const updateEmployeeResponsibility = ({
  employeeId,
  certificateId,
  payload,
}: TVariables) =>
  fetchJson<TData, false, TQueryParams>(
    // @ts-ignore
    `mitarbeiter/${employeeId}/beschaeftigungen/${payload.BeschaeftigungID}`,
    {
      method: "PUT",
      body: payload,
      queryParams: {
        zeugnisId: certificateId,
      },
    }
  )

export const useEmployeeResponsibilityMutation: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(updateEmployeeResponsibility, {
    onMutate(...args) {
      const [vars] = args

      if (vars.showToast) {
        toastId = toast.loading(t("updating"))
      }

      options?.onMutate?.(...args)
    },
    onError(...args) {
      const [, vars] = args

      if (vars.showToast) {
        toast.error(t("error"), { id: toastId })
      }

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      const [, vars] = args

      toast.dismiss()
      if (vars.showToast) {
        toast.success(t("changesSaved"), { id: toastId })
      }

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
