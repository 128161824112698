import * as React from "react"

function DragHandle(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10.23 3.154C10.2368 3.5068 10.1733 3.85742 10.043 4.18535C9.91271 4.51329 9.71835 4.81195 9.47127 5.06387C9.22419 5.3158 8.92936 5.51592 8.60401 5.65253C8.27867 5.78915 7.92935 5.85951 7.57649 5.85951C7.22362 5.85951 6.8743 5.78915 6.54896 5.65253C6.22361 5.51592 5.92878 5.3158 5.6817 5.06387C5.43462 4.81195 5.24026 4.51329 5.10998 4.18535C4.97971 3.85742 4.91614 3.5068 4.92299 3.154C4.92299 2.45012 5.2026 1.77506 5.70032 1.27734C6.19805 0.779617 6.8731 0.5 7.57699 0.5C8.28087 0.5 8.95593 0.779617 9.45365 1.27734C9.95137 1.77506 10.231 2.45012 10.231 3.154H10.23Z"
        stroke="black"
      />
      <path
        d="M10.23 11.9999C10.2368 12.3527 10.1733 12.7034 10.043 13.0313C9.91271 13.3592 9.71835 13.6579 9.47127 13.9098C9.22419 14.1617 8.92936 14.3619 8.60401 14.4985C8.27867 14.6351 7.92935 14.7055 7.57649 14.7055C7.22362 14.7055 6.8743 14.6351 6.54896 14.4985C6.22361 14.3619 5.92878 14.1617 5.6817 13.9098C5.43462 13.6579 5.24026 13.3592 5.10998 13.0313C4.97971 12.7034 4.91614 12.3527 4.92299 11.9999C4.92299 11.2961 5.2026 10.621 5.70032 10.1233C6.19805 9.62556 6.8731 9.34595 7.57699 9.34595C8.28087 9.34595 8.95593 9.62556 9.45365 10.1233C9.95137 10.621 10.231 11.2961 10.231 11.9999H10.23Z"
        stroke="black"
      />
      <path
        d="M10.23 20.846C10.2368 21.1988 10.1733 21.5494 10.043 21.8774C9.91271 22.2053 9.71835 22.504 9.47127 22.7559C9.22419 23.0078 8.92936 23.2079 8.60401 23.3446C8.27867 23.4812 7.92935 23.5515 7.57649 23.5515C7.22362 23.5515 6.8743 23.4812 6.54896 23.3446C6.22361 23.2079 5.92878 23.0078 5.6817 22.7559C5.43462 22.504 5.24026 22.2053 5.10998 21.8774C4.97971 21.5494 4.91614 21.1988 4.92299 20.846C4.92299 20.1421 5.2026 19.4671 5.70032 18.9694C6.19805 18.4716 6.8731 18.192 7.57699 18.192C8.28087 18.192 8.95593 18.4716 9.45365 18.9694C9.95137 19.4671 10.231 20.1421 10.231 20.846H10.23Z"
        stroke="black"
      />
      <path
        d="M19.077 3.154C19.077 3.85788 18.7974 4.53294 18.2996 5.03066C17.8019 5.52838 17.1269 5.808 16.423 5.808C15.7191 5.808 15.044 5.52838 14.5463 5.03066C14.0486 4.53294 13.769 3.85788 13.769 3.154C13.769 2.45012 14.0486 1.77506 14.5463 1.27734C15.044 0.779617 15.7191 0.5 16.423 0.5C17.1269 0.5 17.8019 0.779617 18.2996 1.27734C18.7974 1.77506 19.077 2.45012 19.077 3.154Z"
        stroke="black"
      />
      <path
        d="M19.077 11.9999C19.077 12.7038 18.7974 13.3789 18.2996 13.8766C17.8019 14.3743 17.1269 14.6539 16.423 14.6539C15.7191 14.6539 15.044 14.3743 14.5463 13.8766C14.0486 13.3789 13.769 12.7038 13.769 11.9999C13.769 11.2961 14.0486 10.621 14.5463 10.1233C15.044 9.62556 15.7191 9.34595 16.423 9.34595C17.1269 9.34595 17.8019 9.62556 18.2996 10.1233C18.7974 10.621 19.077 11.2961 19.077 11.9999Z"
        stroke="black"
      />
      <path
        d="M19.077 20.846C19.077 21.5499 18.7974 22.225 18.2996 22.7227C17.8019 23.2204 17.1269 23.5 16.423 23.5C15.7191 23.5 15.044 23.2204 14.5463 22.7227C14.0486 22.225 13.769 21.5499 13.769 20.846C13.769 20.1421 14.0486 19.4671 14.5463 18.9694C15.044 18.4716 15.7191 18.192 16.423 18.192C17.1269 18.192 17.8019 18.4716 18.2996 18.9694C18.7974 19.4671 19.077 20.1421 19.077 20.846Z"
        stroke="black"
      />
    </svg>
  )
}

export default DragHandle
