import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { fetchJson } from "utils/network"

import { orgUnitsKeys } from "./keys"

export type TOrgUnit = {
  Id: number
  NameML: {
    CatMLTextID: number
    Code: string
    de: string
    fr: string
    it: string
    en: string
  }
  ImBereichML: {
    CatMLTextID: number
    Code: string
    de: string
    fr: string
    it: string
    en: string
  }
  DesBereichsML: {
    CatMLTextID: number
    Code: string
    de: string
    fr: string
    it: string
    en: string
  }
  DemselbenBereichML: {
    CatMLTextID: number
    Code: string
    de: string
    fr: string
    it: string
    en: string
  }
  IndenBereichML: {
    CatMLTextID: number
    Code: string
    de: string
    fr: string
    it: string
    en: string
  }
}

type TData = TOrgUnit
type THook = (
  id: number,
  options?: UseQueryOptions<TData>
) => UseQueryResult<TData>

export const useOrgUnit: THook = (id, options) =>
  useQuery(
    orgUnitsKeys.one(id),
    // @ts-ignore
    () => fetchJson<TData>(`OrganizationTypes/${id}`),
    // @ts-ignore
    options
  )
