import * as React from "react"

function SvgUpload(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M72.917 137.077V37.911M49.583 61.244l23.334-23.333L96.25 61.244M137.083 2.91H2.917"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgUpload
