import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { fetchJson } from "utils/network"

import { closingStatementsKeys } from "./keys"

export type TClosingStatement = {
  CatSchlussformelBlockID: number
  CatSchlussformelOptionID: number
  Sequenz: number
  CatZeugnisTypeID: number
  TemplateML: {
    CatMLTextID: number
    Code: string
    de: string
    fr: string
    it: string
    en: string
  }
  ExampleText: {
    Variable: string
    ModDate: string
    CatSatzBausteinID: number
    IsGenderType: true
    ZzMaleText: {
      de: string
      fr: string
      it: string
      en: string
    }
    ZzFemaleText: {
      de: string
      fr: string
      it: string
      en: string
    }
    AzMaleText: {
      de: string
      fr: string
      it: string
      en: string
    }
    AzFemaleText: {
      de: string
      fr: string
      it: string
      en: string
    }
  }
  Code: string
  ModDate: string
}

type TData = TClosingStatement
type THook = (
  id: number,
  options?: UseQueryOptions<TData>
) => UseQueryResult<TData>

const createClosingStatement = (id: number) =>
  // @ts-ignore
  fetchJson<TData>(`CatSchlussformelOptionen/${id}`)

// @ts-ignore
export const useClosingStatement: THook = (id, options) =>
  useQuery(
    closingStatementsKeys.oneStatement(id),
    () => createClosingStatement(id),
    // @ts-ignore
    options
  )
