import { useState, createContext, ReactNode } from "react"

export interface IAppState {
  test: string
}

type TAppContext = {
  appState: IAppState
  setAppState: React.Dispatch<React.SetStateAction<IAppState>>
}

type TProps = {
  children: ReactNode
}

export const AppContext = createContext<TAppContext | null>(null)

// Create Provider
export const AppContextProvider = ({ children }: TProps) => {
  const [appState, setAppState] = useState({
    test: "test",
  })

  return (
    <AppContext.Provider value={{ appState, setAppState }}>
      {children}
    </AppContext.Provider>
  )
}
