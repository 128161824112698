import { AlertTriangleIcon, X } from "lucide-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { cn } from "utils"

const now = new Date()
// Zurich time, just change the date as needed
const targetDate = new Date("2024-11-01T18:00:00+02:00")
const endDate = new Date("2024-11-04T06:00:00+02:00");
const isNowInMaintenanceWindow = now >= targetDate && now <= endDate
const isBeforeMaintenanceWindowEnds = now <= endDate
const alertKey = "SKRIBA_TOP_ALERT_CLOSED_2024-10-29"
const debugKey = "SKRIBA_TOP_ALERT_DEBUG"

export const TopBanner = () => {
  const { t } = useTranslation("general")
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    const alertClosed = localStorage.getItem(alertKey)
    const debugMode = localStorage.getItem(debugKey) === "true"

    if ((!alertClosed && isBeforeMaintenanceWindowEnds) || debugMode || isNowInMaintenanceWindow) {
      setShowAlert(true)
    }
  }, [])

  if (!showAlert) {
    return null
  }

  const closeAlert = () => {
    setShowAlert(false)
    localStorage.setItem(alertKey, "true")
  }

  return (
    <div className="bg-yellow-100 text-yellow-700 p-2 sticky z-50 top-0 w-full flex items-center justify-center gap-2">
      <AlertTriangleIcon className="h-4 w-4" />
      <p>
        {
          isNowInMaintenanceWindow
            ? <>
              <b>{t("top-banner.during-maintenance.title")}</b> {t("top-banner.during-maintenance.text")}
            </>
            : <p>{t("top-banner.text")}</p>
        }
      </p>
      {!isNowInMaintenanceWindow && (
        <button
          className={cn(
            "flex items-center rounded-sm opacity-70 ring-offset-background ml-1",
            "transition-opacity hover:opacity-100 focus:outline-none",
            "focus:ring-2 focus:ring-ring focus:ring-offset-2",
            "disabled:pointer-events-none",
            "data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
          )}
          onClick={closeAlert}
        >
          <X className="h-4 w-4" />
        </button>
      )}
    </div>
  )
}
