import * as React from "react"

function SvgCancel(props) {
  const { pathProps, ...svgProps } = props

  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M2.917 2.91l134.166 134.167M137.083 2.91L2.917 137.078"
          strokeWidth={5.83333}
          {...pathProps}
        />
      </g>
    </svg>
  )
}

export default SvgCancel
