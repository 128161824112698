import * as React from "react"

function SvgBricks(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M4.9 135.1l38.267-38.267M38.442 7.642a18.783 18.783 0 0111.666-4.725h80.5a6.767 6.767 0 016.475 6.708v80.5a18.783 18.783 0 01-4.725 11.667l-30.8 30.8a18.783 18.783 0 01-11.666 4.725H9.625a6.767 6.767 0 01-6.708-6.942v-80.5a18.783 18.783 0 014.725-11.667z"
          strokeWidth={5.83333}
        />
        <path d="M135.45 96.833H43.167V4.55" strokeWidth={5.83333} />
        <path
          d="M96.833 135.45V43.167L135.1 4.9M96.833 43.167H4.55"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgBricks
