import * as _ from "lodash-es"
import posthog from "posthog-js"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { ICertificate } from "./useCertificate"

type TQueryParams = operations["Zeugnisse_PostDuplicate"]["parameters"]["query"]

type TData = ICertificate
type TError = unknown
type TVariable = number
type THook = (
  options?: UseMutationOptions<TData, TError, TVariable>
) => UseMutationResult<TData, TError, TVariable>

export const useDuplicateCertificate: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(
    (certificateId) =>
      // @ts-ignore
      fetchJson<TData, false, TQueryParams>(`Zeugnisse/${certificateId}`, {
        method: "POST",
        queryParams: { clearFreeEditText: true },
      }),
    {
      onMutate(...args) {
        toastId = toast.loading(t("inProgress"))

        void posthog.capture("Certificate duplicated", {
          certificateId: args[0],
        })

        options?.onMutate?.(...args)
      },
      onError(...args) {
        toast.error(t("error"), { id: toastId })

        void options?.onError?.(...args)
      },
      onSuccess(...args) {
        toast.success(t("done"), { id: toastId })

        void options?.onSuccess?.(...args)
      },
      ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
    }
  )
}
