import * as React from "react"

function SvgNotes(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M96.25 90.417a5.833 5.833 0 00-5.833 5.833v40.833H8.75a5.833 5.833 0 01-5.833-5.833V8.75A5.833 5.833 0 018.75 2.917h122.5a5.833 5.833 0 015.833 5.833v81.667zM137.083 90.417l-46.666 46.666"
          strokeWidth={5.83333}
        />
        <path
          d="M29.167 58.333L35 52.5a8.254 8.254 0 0111.667 0 8.254 8.254 0 0011.666 0A8.254 8.254 0 0170 52.5a8.254 8.254 0 0011.667 0 8.254 8.254 0 0111.666 0 8.254 8.254 0 0011.667 0l5.833-5.833M29.167 87.5L35 81.667a8.254 8.254 0 0111.667 0 8.254 8.254 0 0011.666 0"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgNotes
