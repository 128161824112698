import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { EGenders } from "src/constants"
import { TLanguageCodes } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { closingStatementsKeys } from "./keys"
import { TClosingStatementGroup } from "./useClosingStatementsGroups"
import { ECertificateTypes } from "../useCertificateTypes"

type TQueryParams =
  operations["CatSchlussformelBlocks_GetById"]["parameters"]["query"]

type TData = TClosingStatementGroup
type TParams = {
  certificateType?: ECertificateTypes
  gender?: EGenders
  language?: TLanguageCodes
}
type TArgs = {
  id: number
  params: TParams
  options?: UseQueryOptions<TData>
}
type THook = (args: TArgs) => UseQueryResult<TData>

function fetchClosingStatementsGroups(id: TArgs["id"], params: TParams) {
  // console.log("fetchClosingStatementsGroups", id, params)
  if (id === 5) return null // for section introduction

  return id && params.certificateType && params.gender && params.language
    ? // @ts-ignore
      fetchJson<TData, false, TQueryParams>(`CatSchlussformelBlocks/${id}`, {
        queryParams: {
          filterAndRenderZeugnisTypId: params.certificateType,
          renderGeschlechtId: params.gender,
          renderSpracheId: params.language,
        },
      })
    : Promise.reject(new Error("Missing id and/or params"))
}

export const useClosingStatementsGroup: THook = (args) => {
  const { id, params, options } = args
  return useQuery(
    closingStatementsKeys.oneGroup(id, params),
    () => fetchClosingStatementsGroups(id, params),
    // @ts-ignore
    {
      ...options,
      enabled:
        (options?.enabled ?? true) &&
        Boolean(
          id && params.certificateType && params.gender && params.language
        ),
    }
  )
}
