import * as React from "react"

function SvgDuplicate(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M99.668 2.917a5.833 5.833 0 014.124 1.709l19.915 19.915a5.833 5.833 0 011.71 4.124v85.085a5.833 5.833 0 01-5.834 5.833H37.917a5.833 5.833 0 01-5.834-5.833v-105a5.833 5.833 0 015.834-5.833z"
          strokeWidth={5.83333}
        />
        <path
          d="M107.917 119.583v11.667a5.833 5.833 0 01-5.834 5.833H20.417a5.833 5.833 0 01-5.834-5.833v-105a5.833 5.833 0 015.834-5.833h11.666"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgDuplicate
