import * as React from "react"

function SvgChevronRight(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.964 137.083l73.36-64.901a2.917 2.917 0 000-4.364L32.964 2.917"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={5.83333}
      />
    </svg>
  )
}

export default SvgChevronRight
