import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { ELanguages } from "src/constants"
import { fetchJson } from "utils/network"

import { DEFAULT_REQUEST_FUNCTION_PARAMS } from "./useFunctionMutation"
import { IFunction } from "./useFunctions"

type TData = IFunction
type TError = unknown
type TPayload = number
type TQueryParams = {
  renderGeschlechtId: number
  renderSpracheId: ELanguages
}
type THook = (
  params: Partial<TQueryParams>,
  options: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

const deleteFunction = (id: TPayload, params: Partial<TQueryParams>) =>
  // @ts-ignore
  fetchJson<TData, false, TQueryParams>(`CatFunktionen/${id}`, {
    queryParams: {
      ...DEFAULT_REQUEST_FUNCTION_PARAMS,
      ...params,
    },
    method: "DELETE",
  })

export const useDeleteFunction: THook = (params, options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation((id) => deleteFunction(id, params), {
    onMutate(...args) {
      toastId = toast.loading(t("inProgress"))

      options?.onMutate?.(...args)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("done"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
