import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { DEFAULT_REQUEST_FUNCTION_PARAMS } from "./useFunctionMutation"
import { IFunction } from "./useFunctions"

type TQueryParams = operations["CatFunktionen_GetById"]["parameters"]["query"]

type TData = IFunction
type TError = unknown
type TVariable = number
type TArgs = {
  options?: UseMutationOptions<TData, TError, TVariable>
}
type THook = ({ options }: TArgs) => UseMutationResult<TData, TError, TVariable>

const getFunction = (id: number) =>
  // @ts-ignore
  fetchJson<IFunction, false, TQueryParams>(`CatFunktionen/${id}`, {
    queryParams: DEFAULT_REQUEST_FUNCTION_PARAMS,
  })

export const useFunctionDeferred: THook = ({ options } = {}) =>
  useMutation(getFunction, options)
