export { default as Locked } from "./Locked"
export * from "./ActiveDirectory"
export * from "./Google"
export * from "./Google2"
export { default as AlertCircle } from "./AlertCircle"
export { default as ArrowRight } from "./ArrowRight"
export { default as Bricks } from "./Bricks"
export { default as Book } from "./Book"
export { default as Bubbles } from "./Bubbles"
export { default as Calendar } from "./Calendar"
export { default as Cancel } from "./Cancel"
export { default as Cards } from "./Cards"
export { default as Certificates } from "./Certificates"
export { default as Checkmark } from "./Checkmark"
export { default as ChevronDown } from "./ChevronDown"
export { default as ChevronLeft } from "./ChevronLeft"
export { default as ChevronRight } from "./ChevronRight"
export { default as ChevronUpDown } from "./ChevronUpDown"
export { default as Dashboard } from "./Dashboard"
export { default as Download } from "./Download"
export { default as Duplicate } from "./Duplicate"
export { default as EditCertificate } from "./EditCertificate"
export { default as Employees } from "./Employees"
export { default as Filter } from "./Filter"
export { default as Find } from "./Find"
export { default as Garbage } from "./Garbage"
export { default as Give } from "./Give"
export { default as HorizontalDots } from "./HorizontalDots"
export { default as Login } from "./Login"
export { default as Logout } from "./Logout"
export { default as Notes } from "./Notes"
export { default as Pencil } from "./Pencil"
export { default as Plus } from "./Plus"
export { default as Settings } from "./Settings"
export { default as SmileySmile } from "./SmileySmile"
export { default as SmileySmirk } from "./SmileySmirk"
export { default as SmileyIndifferent } from "./SmileyIndifferent"
export { default as Support } from "./Support"
export { default as Table } from "./Table"
export { default as Unlocked } from "./Unlocked"
export { default as Upload } from "./Upload"
export { default as UserManagement } from "./UserManagement"
export { default as DragHandle } from "./DragHandle"
export { default as ChevronDown2 } from "./ChevronDown2"
export { default as UserLock } from "./UserLock"
export { default as User } from "./User"
export { default as UserEdit } from "./UserEdit"
export { default as Warning } from "./Warning"
export { default as SendEmailEnvelope } from "./SendEmailEnvelope"
export { default as PersonioIcon } from "./PersonioIcon"

export * from "./LogoMicrosoft"
