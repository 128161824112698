import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { EGenders } from "src/constants"
import { TLanguageCodes } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { TClosingStatementGroup } from "./useClosingStatementsGroups"
import { ECertificateTypes } from "../useCertificateTypes"

type TQueryParams =
  operations["CatSchlussformelBlocks_Put"]["parameters"]["query"]

type TData = TClosingStatementGroup
type TError = unknown
type TVariables = {
  certificateType: ECertificateTypes
  gender: EGenders
  language: TLanguageCodes
  payload: TClosingStatementGroup
  showToast?: true
}
type THook = (
  options?: UseMutationOptions<TData, TError, TVariables>
) => UseMutationResult<TData, TError, TVariables>

function updateClosingStatementGroup(params: TVariables) {
  const { payload, certificateType, gender, language } = params

  return fetchJson<TData, false, TQueryParams>(
    // @ts-ignore
    `CatSchlussformelBlocks/${payload.CatSchlussformelBlockID}`,
    {
      method: "PUT",
      body: payload,
      queryParams: {
        filterAndRenderZeugnisTypId: certificateType,
        renderGeschlechtId: gender as unknown as number,
        renderSpracheId: language,
      },
    }
  )
}

export const useClosingStatementsGroupMutation: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(updateClosingStatementGroup, {
    onMutate(variables) {
      if (variables.showToast) {
        toastId = toast.loading(t("updating"))
      }

      options?.onMutate?.(variables)
    },
    onError(...args) {
      const [, { showToast }] = args

      if (showToast) {
        toast.error(t("error"), { id: toastId })
      }

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      const [, { showToast }] = args

      if (showToast) {
        toast.success(t("changesSaved"), { id: toastId })
      }

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
