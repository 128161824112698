import { useLayoutEffect } from "react"

function useLockBodyScroll(): void {
  useLayoutEffect(() => {
    // Prevent scrolling on mount
    document.body.style.overflow = "hidden"

    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = "visible"
    }
  }, [])
}

export default useLockBodyScroll
