import { IEmployeesQueryParams } from "./useEmployees"
import { TEmployeesForTableQueryParams } from "./useEmployeesForTableInfinite"

export const employeeKeys = {
  all(
    params?: IEmployeesQueryParams
  ): ["EMPLOYEES"] | ["EMPLOYEES", IEmployeesQueryParams] {
    if (params) {
      return ["EMPLOYEES", params]
    }

    return ["EMPLOYEES"]
  },
  allForTable: (
    params?: TEmployeesForTableQueryParams
  ):
    | ["EMPLOYEES_FOR_TABLE"]
    | ["EMPLOYEES_FOR_TABLE", TEmployeesForTableQueryParams] => {
    if (params) {
      return ["EMPLOYEES_FOR_TABLE", params]
    }

    return ["EMPLOYEES_FOR_TABLE"]
  },
  allForTableInfinite: (
    params?: TEmployeesForTableQueryParams
  ):
    | ["EMPLOYEES_FOR_TABLE_INFINITE"]
    | ["EMPLOYEES_FOR_TABLE_INFINITE", TEmployeesForTableQueryParams] => {
    if (params) {
      return ["EMPLOYEES_FOR_TABLE_INFINITE", params]
    }

    return ["EMPLOYEES_FOR_TABLE_INFINITE"]
  },
  one: (id?: number) => [...employeeKeys.all(), id] as const,
}
