import { AxiosError } from "axios"
import * as _ from "lodash-es"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

type TData = void
type TError = unknown
type TPayload =
  operations["Benutzer_PostResetPassword"]["parameters"]["body"]["dto"]
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

export const useResetPassword: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  return useMutation(
    (payload) =>
      fetchJson<TData>(`benutzer/resetpassword`, {
        method: "POST",
        body: payload,
      }),
    {
      onError(...args) {
        const error = args[0] as AxiosError<{ Message?: string }>
        if (
          error.response?.status === 400 &&
          error.response?.data.Message === "WRONG_PASSWORD"
        ) {
          // Handle this error at the call site,
          // e.g. display error next to the related field.
        } else {
          toast.error(t("error"))
        }

        void options?.onError?.(...args)
      },
      onSuccess(...args) {
        toast.success(t("changesSaved"))

        void options?.onSuccess?.(...args)
      },
      ..._.omit(options, ["onError", "onSuccess"]),
    }
  )
}
