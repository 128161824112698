import { useMutation, UseMutationOptions } from "react-query"
import { z } from "zod"

import { TIntegrationType } from "@/components/pages/admin/integrations/types"
import { type TEmployee } from "types"
import { fetchJson } from "utils/network"

import { TMapping } from "./types"

export type UseToSkribaEmployeesParams = {
  mapping: TMapping
  integrationType: TIntegrationType
}

const ToSkribaEmployeesResponseSchema = z.object({
  skribaEmployees: z.array(z.custom<TEmployee>()),
  jobRunId: z.string(),
})

type TToSkribaEmployeesResponse = z.infer<
  typeof ToSkribaEmployeesResponseSchema
>

export function useToSkribaEmployees(
  options?: UseMutationOptions<
    TToSkribaEmployeesResponse,
    Error,
    UseToSkribaEmployeesParams
  >
) {
  return useMutation<
    TToSkribaEmployeesResponse,
    Error,
    UseToSkribaEmployeesParams
  >(
    async ({ mapping, integrationType }) => {
      const maybeResponse = await fetchJson(
        "api/ink-integrations/run-job", {
        useLocalAir: true,
          method: "POST",
          body: {
            mapping,
            integrationType,
          },
        }
      )

      const response = ToSkribaEmployeesResponseSchema.parse(maybeResponse)

      return response
    },
    {
      ...options,
    }
  )
}
