import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
  useQueryClient,
} from "react-query"

import { fetchJson } from "utils/network"

import { employeeKeys, IEmployee } from "."

type TData = IEmployee
type TError = unknown
type TPayload = IEmployee
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

const updateEmployee = (payload: TPayload) =>
  // @ts-ignore
  fetchJson<TData>(`Mitarbeiter/${payload.MitarbeiterID}`, {
    method: "PUT",
    body: _.omit(payload, "EigeneZeugnisse"),
  })

export const useEmployeeMutation: THook = (options) => {
  const queryClient = useQueryClient()

  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(updateEmployee, {
    onMutate(...args) {
      toastId = toast.loading(t("updating"))

      options?.onMutate?.(...args)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      const [data] = args

      queryClient.setQueryData(employeeKeys.one(data.MitarbeiterID), data)

      toast.success(t("changesSaved"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
