import { AxiosResponse } from "axios"
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { TLanguageCodes, TODataParams } from "types"
import { fetchJson } from "utils/network"

type TText = Record<TLanguageCodes, string | null>

export type TTextComponent = {
  Variable: string
  ModDate: string
  CatSatzBausteinID: number
  IsGenderType: boolean
  AzFemaleText: TText
  AzMaleText: TText
  ZzFemaleText: TText
  ZzMaleText: TText
}

type TData = Record<string, TTextComponent>

export const useTextComponents = <TFullResponse>(
  params?: TODataParams,
  options?: UseQueryOptions<
    TFullResponse extends true ? AxiosResponse<TData> : TData
  >
): UseQueryResult<TFullResponse extends true ? AxiosResponse<TData> : TData> =>
  useQuery(
    "TEXT_COMPONENTS",
    () =>
      fetchJson<TData, TFullResponse, TODataParams>("textcomponents", {
        queryParams: params,
        fullResponse: params?.$inlinecount === "allpages",
      }),
    // @ts-ignore
    options
  )
