import * as React from "react"

function SvgDashboard(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M2.917 70.006a67.083 67.083 0 10134.166 0 67.083 67.083 0 10-134.166 0z"
          strokeWidth={5.83333}
        />
        <path
          d="M14.583 78.75h13.849c12.868 0 11.754-10.89 21.192-56.187a2.917 2.917 0 015.711 0l17.862 87.104a2.917 2.917 0 005.693.099l12.302-51.258a2.917 2.917 0 015.548-.402c4.334 10.838 6.067 20.656 16.158 20.656h12.519"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgDashboard
