import * as React from "react"

function SvgGive(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M137.083 128.333l-5.833-11.281V90.417c.245-10.372-12.14-19.618-23.333-29.167M116.777 102.083l-14.88-15.207A7.263 7.263 0 1091.49 97.008l16.427 16.742v8.75a37.502 37.502 0 005.833 14.583M2.917 11.667L8.75 22.948v26.635C8.505 59.955 20.89 69.201 32.083 78.75M23.222 37.917l14.881 15.207A7.263 7.263 0 1048.51 42.992L32.083 26.25V17.5A37.502 37.502 0 0026.25 2.917"
          strokeWidth={5.83333}
        />
        <path
          d="M32.083 46.97v66.78a5.833 5.833 0 005.834 5.833h55.416M107.917 93.03V26.25a5.833 5.833 0 00-5.834-5.833H43.75"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgGive
