import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query"

import { fetchJson } from "utils/network"

import { wordTemplateKeys } from "./keys"
import { TWordTemplate } from "./useWordTemplates"

type TData = TWordTemplate
type TError = unknown
type TPayload = number
type TContext = {
  previousTemplates?: TWordTemplate[]
}
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload, TContext>
) => UseMutationResult<TData, TError, TPayload, TContext>

const deleteWordTemplate = (id: TPayload) =>
  // @ts-ignore
  fetchJson<TData>(`ZeugnisTemplate/${id}`, { method: "DELETE" })

export const useDeleteWordTemplate: THook = (options) => {
  const queryClient = useQueryClient()
  const key = wordTemplateKeys.all

  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(deleteWordTemplate, {
    async onMutate(id) {
      toastId = toast.loading(t("inProgress"))

      await queryClient.cancelQueries(key)

      const previousTemplates = queryClient.getQueryData<TWordTemplate[]>(key)

      if (previousTemplates) {
        queryClient.setQueryData(
          key,
          previousTemplates.filter(
            (template) => template.ZeugnisTemplateID !== id
          )
        )
      }

      return { previousTemplates }
    },
    onError(...args) {
      const [, , context] = args

      if (context?.previousTemplates) {
        queryClient.setQueryData(key, context.previousTemplates)
      }

      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("done"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
