import * as React from "react"

function SvgSmileySmile(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      height="100%"
      width="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect height="100%" width="100%" rx={12} fill="currentColor" />
      <g stroke="#000" strokeLinecap="round" strokeLinejoin="round">
        <path d="M6.5 9.75a.25.25 0 110 .5.25.25 0 010-.5zM17.5 9.75a.25.25 0 100 .5.25.25 0 000-.5zM6 15.5a6.5 6.5 0 0012 0" />
        <path d="M.5 12a11.5 11.5 0 1023 0 11.5 11.5 0 00-23 0v0z" />
      </g>
    </svg>
  )
}

export default SvgSmileySmile
