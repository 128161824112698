import * as React from "react"

function SvgPencil(props) {
  return (
    <svg
      viewBox="0 0 140 140"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M103.085 12.17l24.749 24.748-87.792 87.792-24.749-24.748zM15.29 99.96L2.916 137.083l37.123-12.372L15.29 99.96zM127.832 36.913l-24.745-24.745 4.124-4.124a17.5 17.5 0 0124.745 24.745z"
          strokeWidth={5.83333}
        />
      </g>
    </svg>
  )
}

export default SvgPencil
